import globalSectionStyles from 'assets/styles/modules/Section.module.scss';
import styles from 'assets/styles/modules/LineSection.module.scss';
import Bin from 'assets/icons/Bin';
import CircleIcon from 'assets/icons/CircleIcon';
import Plus from 'assets/icons/Plus';
import {useState} from 'react';
import useSwipeHandlers from 'lib/hooks/useSwipeHandlers';
import {motion} from 'framer-motion';
import {spring} from 'utilities/animations';
import useSelectTemplate from 'lib/hooks/useSelectTemplate';

const LineSection = ({content, isShowPage, openEdit, removeSection}) => {
  const [active, setActive] = useState(false);
  const [tempTitle, setTempTitle] = useState(null);
  const {swipeHandlers, layout} = useSwipeHandlers(
    content?.variationNumber || 1,
  );
  useSelectTemplate(content, layout === 0 ? 1 : layout);

  return (
    <>
      <section
        className={globalSectionStyles.container}
        {...(isShowPage ? [] : swipeHandlers)}
        draggable="false"
        style={{userSelect: 'none'}}
      >
        <div
          className={`${
            isShowPage
              ? globalSectionStyles.contentFullPageView
              : globalSectionStyles.contentEditView
          } ${styles.content}`}
          data-layout={layout === 0 ? 1 : layout}
        >
          <motion.div
            className={styles.line}
            layout
            transition={spring}
          ></motion.div>
        </div>

        {openEdit && (
          <div
            className={globalSectionStyles.delete}
            onClick={() => removeSection(content.rowId)}
          >
            <CircleIcon variant={'filledInverted'} iconWidth={'w-14'}>
              <Bin />
            </CircleIcon>
          </div>
        )}

        {openEdit && (
          <div
            className={globalSectionStyles.addSection}
            onClick={() =>
              openEdit({status: true, afterPosition: content.position})
            }
          >
            <CircleIcon iconWidth={'w-12'}>
              <Plus />
            </CircleIcon>
          </div>
        )}
      </section>

      {openEdit && <div className={globalSectionStyles.divider}></div>}
    </>
  );
};

export default LineSection;
