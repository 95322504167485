import { AnimatePresence } from 'framer-motion';

import useScrollBlock from 'lib/hooks/useScrollBlock';

import styles from 'assets/styles/modules/Modal.module.scss';

import SectionAbout from 'assets/icons/SectionAbout';
import SectionButton from 'assets/icons/SectionButton';
import SectionGallery from 'assets/icons/SectionGallery';
import SectionImages from 'assets/icons/SectionImages';
import SectionQuote from 'assets/icons/SectionQuote';
import SectionText from 'assets/icons/SectionText';

import Modal from '../Modal';
import ModalHeading from '../ModalHeading';
import ModalSectionType from '../ModalSectionType';
import ModalBody from '../ModalBody';
import SectionContact from 'assets/icons/SectionContact';

const Section = ({ addSection, afterPosition, children, id, setActive, text, title, }) => {
  const handleAddSection = () => {
    addSection(id, Number(afterPosition) + 1);
    setActive({ afterPosition: 0, status: false, });
  };

  return (
    <div className={styles.addSectionButton} onClick={ handleAddSection }>
      <h3>{ title }</h3>
      <span>+</span>
    </div>
  );
};

const SectionsModal = ({ active, setActive, addSection }) => {
  useScrollBlock(active.status);

  return (
    <AnimatePresence>
      {active.status && (
        <Modal handleClose={ () => setActive({ afterPosition: 0, status: false, }) } fullsize={true}>
          <ModalHeading handleHeadingClose={ () => setActive({ afterPosition: 0, status: false, }) } title={'Add Section'} />
          
          <ModalBody activeLevel={active}>
            <div className={styles.addSectionWrapper}>
              <h2>Templates</h2>
              <div className={ `${styles.sizer} flex flex-col` }>
                <Section addSection={ addSection } afterPosition={ active.afterPosition } id='gallery' setActive={ setActive } text='This section contains a Title, multiple Images and a Text.' title='Gallery'>
                  <SectionGallery />
                </Section>
                
                <Section addSection={ addSection } afterPosition={ active.afterPosition } id='about' setActive={ setActive } text='This section contains a Title, an Image and a Text.' title='About'>
                  <SectionAbout />
                </Section>

                <Section addSection={ addSection } afterPosition={ active.afterPosition } id='contact' setActive={ setActive } text='This section contains a Keep in touch section.' title='Contact'>
                  <SectionContact />
                </Section>
              </div>

              <h2>Essentials</h2>
              <div className={ `${styles.sizer} flex flex-col` }>
                <Section addSection={ addSection } afterPosition={ active.afterPosition } id='quote' setActive={ setActive } text='This section contains a Text.' title='Quote'>
                  <SectionQuote />
                </Section>

                <Section addSection={ addSection } afterPosition={ active.afterPosition } id='button' setActive={ setActive } text='This section contains a Button.' title='Button'>
                  <SectionButton />
                </Section>

                <Section addSection={ addSection } afterPosition={ active.afterPosition } id='text' setActive={ setActive } text='This section contains Highlights and Text.' title='Text'>
                  <SectionText />
                </Section>

                <Section addSection={ addSection } afterPosition={ active.afterPosition } id='images' setActive={ setActive } text='This section contains multiple Images.' title='Images'>
                  <SectionImages />
                </Section>

                <Section addSection={ addSection } afterPosition={ active.afterPosition } id='link' setActive={ setActive } text='This section contains a link.' title='Link'>
                  <SectionButton />
                </Section>

                <Section addSection={ addSection } afterPosition={ active.afterPosition } id='line' setActive={ setActive } text='This section contains a line.' title='Line'>
                  <SectionButton />
                </Section>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default SectionsModal;
