import Avatar from "../Avatar";
import styles from "assets/styles/modules/ProjectItemHeader.module.scss";
import moment from "moment";

const ProjectItemHeader = ({ experiment }) => {
  const date = experiment?.createdAt
    ? moment(experiment.createdAt).format("LL")
    : null;

  return (
    <div className={styles.container}>
      {experiment && (
        <>
          <a href={`/user/${experiment.creator?._id}`}>
            <Avatar
              name={experiment.creator?.username}
              date={date}
              img={experiment.creator?.avatar}
              small
            />
          </a>
          <h3 className={`sp1 font-semibold uppercase ${styles.title}`}>
            {experiment.title ? experiment.title : "Unknown Title"}
          </h3>
        </>
      )}
    </div>
  );
};

export default ProjectItemHeader;
