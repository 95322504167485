const Search = ({color}) => {
  return (
    <svg viewBox="0 0 13.72 13.72">
      <path
        d="m10.5,6c0,2.49-2.01,4.5-4.5,4.5S1.5,8.49,1.5,6,3.51,1.5,6,1.5s4.5,2.01,4.5,4.5Zm-.82,4.74c-1.02.79-2.29,1.26-3.68,1.26-3.31,0-6-2.69-6-6S2.69,0,6,0s6,2.69,6,6c0,1.39-.47,2.66-1.26,3.68l2.76,2.76c.29.29.29.77,0,1.06-.29.29-.77.29-1.06,0l-2.76-2.76Z"
        fill={color ? color : '#2f2f2f'}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Search;
