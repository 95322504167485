import React, { useEffect, useRef, useState } from "react";
import styles from "assets/styles/modules/LoadingOrb.module.scss";

function LoadingOrb({ generationId, triggerRedirect, }) {
  useEffect(() => {
    async function beginAnimation() {
      document.getElementById('loadingOrbVideo').play();
      document.getElementById('creation-scrollable').scrollTo({ left: 10000, behavior: 'smooth', });

      const circleSvgElem = document.getElementById('circle-progress-svg');
      const percentageTextElem = document.getElementById('circle-progress-text');

      const initialValue = Number((window.getComputedStyle(circleSvgElem).strokeDashoffset || '0px').split('px')[0]);
      const timeoutPerTick = 90000 / initialValue; // total of 1.5 minutes

      // state doesn't update while in here -- makes it difficult to check when something is actually done...
      for (let i = initialValue; i > 0; i--) {
        circleSvgElem.style.strokeDashoffset = `${i}px`;
        let percentageCompletion = Math.round(100 - (i / initialValue) * 100);
        percentageTextElem.innerHTML = `${percentageCompletion}%`;

        let timeoutLength = localStorage.getItem(generationId) ? 1 : (timeoutPerTick * ((percentageCompletion + 1) / 50));      
        await new Promise((resolve) => setTimeout(resolve, timeoutLength));
      }

      for (let i = 0; i <= initialValue + 10; i += 10) {
        circleSvgElem.style.strokeDashoffset = `${-i}px`;
        await new Promise((resolve) => setTimeout(resolve, 0));
      }

      // stall until generation is completed (it should complete around 65% above)
      while (!localStorage.getItem(generationId)) {
        await new Promise((resolve) => setTimeout(resolve, 15));
      }

      triggerRedirect();
    }

    if (!!generationId) setTimeout(beginAnimation, 200);
  }, [generationId]);

  return (
    <div className={styles.loadingRingSpacer}>
      <div className={styles.loadingRing} id="generation-circle">
        <div className={styles.loadingRingValue} id="circle-progress-text">
          0%
        </div>

        <svg className={styles.loadingRingProgress} preserveAspectRatio="xMinYMin meet" version="1.1" viewBox="0 0 300 300">
          <circle
            className=""
            id="circle-progress-svg"
            r="149.5"
            cx="150"
            cy="150"
            fill="transparent"
            stroke="#2F2F2F"
            strokeDasharray="939"
            strokeDashoffset="939"
            strokeWidth="2px"
          ></circle>
        </svg>

        <div className={styles.orbVideoContainer}>
          <video className={styles.orbVideo} id='loadingOrbVideo' loop muted playsInline preload='auto'>
            <source src='https://paralllel-webpage-assets.s3.eu-west-3.amazonaws.com/videos/loop-fullscreen.mp4' type='video/mp4' />
          </video>
        </div>
      </div>
    </div>
  )
}

export default LoadingOrb;
