import Close from "assets/icons/Close";
import styles from "assets/styles/modules/FullScreenMessage.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import { modalAnimation, revealOpacity } from "utilities/animations";

const FullScreenMessage = ({ children, active, setActive }) => {
  return (
    <AnimatePresence>
      {active && (
        <motion.div
          className={`${styles.wrapper}`}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={revealOpacity}
        >
          <div className={`${styles.wrapper}`}>
            <div
              className={styles.overlay}
              onClick={() => setActive(false)}
            ></div>

            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={modalAnimation}
              className={styles.body}
            >
              <div className={styles.close} onClick={() => setActive(false)}>
                <Close color={"#1c1c1c"} />
              </div>
              <div>{children}</div>
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FullScreenMessage;
