import React from "react";
import cn from "classnames";
import styles from "assets/styles/modules/FormFieldInfo.module.scss";

const FormCtaInfo = ({ title, text }) => {
  return (
    <div className={styles.card}>
      <div className="sp1">{title}</div>
      <div className={styles.buttonContainer}>
        <div className={cn([styles.button, {
            [styles.disabled]: !text
        }])}>{text || 'Contact me'}</div>
      </div>
    </div>
  );
};

export default FormCtaInfo;
