const QrCode = ({color}) => {
  return (
    <svg viewBox="0 0 14 14">
      <path
        d="m1.5,1.5h1v1h-1v-1Zm-1.5,2.5V0h4v4H0Zm1.5,8.5v-1h1v1h-1Zm-1.5,1.5v-4h4v4H0ZM12.5,1.5h-1v1h1v-1Zm-2.5-1.5v4h4V0h-4Zm-2,0h-2v2h2V0Zm-2,6h2v2h-2v-2Zm8,0h-2v2h2v-2Zm-3,0v2h-2v-2h2Zm-3,6h-2v2h2v-2Zm-2-3h2v2h-2v-2Zm5,3v2h-2v-2h2Zm3-3h-2v2h2v-2Zm-2,3h2v2h-2v-2Zm-7-6h-2v2h2v-2Zm1-3h2v2h-2v-2Zm-4,3H0v2h2v-2Zm9,3h-2v2h2v-2Z"
        fill={color ? color : '#2f2f2f'}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default QrCode;
