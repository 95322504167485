import React from "react";
import styles from "assets/styles/modules/FormEditImage.module.scss";
import FormImageAdd from "./FormImageAdd";
import cn from "classnames";

const FormEditImage = ({
  errors,
  img,
  multiple,
  rowId,
  setFieldValue,
  setTempImage,
  touched,
  openImageEditModal,
  openGenerateImageModal,
}) => {
  return (
    <div className={cn(styles.container, { [styles.multiple]: multiple })}>
      <FormImageAdd
        errors={errors}
        id="file"
        img={img || null}
        openGenerateImageModal={openGenerateImageModal}
        openImageEditModal={openImageEditModal}
        rowId={rowId}
        setFieldValue={setFieldValue}
        setTempImage={setTempImage}
        title=""
        touched={touched}
      />
    </div>
  );
};

export default FormEditImage;
