const Download = () => {
  return (
    <svg viewBox="0 0 10 13.75">
      <path
        id="Union"
        d="m4.25.75v6.68l-2.43-2.43c-.29-.29-.77-.29-1.06,0-.29.29-.29.77,0,1.06l3.54,3.54c.39.39,1.02.39,1.41,0l3.54-3.54c.29-.29.29-.77,0-1.06-.29-.29-.77-.29-1.06,0l-2.43,2.43V.75c0-.41-.34-.75-.75-.75s-.75.34-.75.75Zm5,13c.41,0,.75-.34.75-.75s-.34-.75-.75-.75H.75c-.41,0-.75.34-.75.75s.34.75.75.75h8.5Z"
        fill="#262626"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Download;
