import styles from 'assets/styles/modules/ShareModal.module.scss';
import {modalAnimation} from 'utilities/animations';
import {motion} from 'framer-motion';
import Close from 'assets/icons/Close';

const DeleteModal = ({close, confirm, height, }) => {
  return (
    <motion.div animate="visible" className={`${styles.wrapper}`} exit="hidden" initial="hidden" variants={modalAnimation}>
      <div className={`${styles.overlay}`} onClick={close}>
      </div>

      <div className={`${styles.container} shadow-md`} style={{height: height + 'px'}}>
        <button className={styles.close} onClick={close}>
          <span className="flex w-9">
            <Close color="#2f2f2f" />
          </span>
        </button>

        <div className={ styles.deletionText } >
          <p>
            You are about to delete something. Once deleted, it cannot be brought back.
          </p>

          <br />
          
          <p>
            Are you sure you would like to delete this?
          </p>

          <br />

          <button className="button button--filled w-full" onClick={ () => { confirm(); close(); } }>Delete</button>
        </div>
      </div>
    </motion.div>
  );
};

export default DeleteModal;
