import {useState} from 'react';

import cn from 'classnames';
import Edit from 'assets/icons/Edit';
import styles from 'assets/styles/modules/DefaultButton.module.scss';

const EditButton = ({action, square}) => {
  const [clickDisabled, setClickDisabled] = useState(false);

  const handleClick = () => {
    // if (!clickDisabled) action();
    // setClickDisabled(true);
    action();
  };

  return (
    <span className={ styles.iconWithText }>
      <button className={ styles.iconButton + ' ' + styles.updatedRounded } onClick={handleClick}>
        <div className={cn(['w-16', styles.icon])}>
          <Edit />
        </div>
      </button>

      <span>Edit</span>
    </span>
  );
};

export default EditButton;
