import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import cn from "classnames";
import useSwipe from "lib/hooks/useSwipe";
import FullScreenModal from "../FullScreenModal";
import styles from "assets/styles/modules/ImageEditModal.module.scss";
import Dots from "../Dots";
import { PromptTextSchema } from "schemas";
import { Formik, Form } from "formik";
import imageGenerating from "assets/images/slide-generating-image.svg";
import imageGenerate from "assets/images/slide-generate.svg";
import FormPromptTextarea from "../Forms/FormPromptTextarea";
import ArrowRight from "assets/icons/ArrowRight";
import { useParams } from "react-router-dom";
import useExperimentSections from "lib/hooks/useExperimentSections";
import Check from "assets/icons/Check";

const GenerateImageModal = ({ setTempImage, active, handleClose, slot, }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);
  const [slides, setSlides] = useState([]);
  const container = useRef(null);
  const { experimentId } = useParams();
  const sections = useExperimentSections();

  const handleNextSlide = () => {
    if (slides) {
      if (currentSlide === slides.length - 1) {
        setCurrentSlide(0);
      } else {
        setCurrentSlide((prevSlide) => prevSlide + 1);
      }
    }
  };

  const handlePrevSlide = () => {
    if (slides) {
      if (currentSlide === 0) {
        setCurrentSlide(slides.length - 1);
      } else {
        setCurrentSlide((prevSlide) => prevSlide - 1);
      }
    }
  };

  const swipeHandlers = useSwipe({
    onSwipedLeft: handleNextSlide,
    onSwipedRight: handlePrevSlide,
  });

  const handleSubmit = () => {
    setTempImage(slides[currentSlide].image, slot);
    handleClose();
  };

  const handlePromptSubmit = async (values) => {
    if (!values.prompt) return;

    values.experimentId = experimentId;

    setIsGenerating(true);
    const data = await sections.generateImages(values);

    if (data) {
      setIsGenerating(false);
      // we need to add an empty slide at the end to show the generate image
      setSlides([...data.generatedImages, {}]);
    }
  };

  // Animate the slide change
  useEffect(() => {
    if (container.current) container.current.scrollTo({ left: (container.current.offsetWidth - 60) * currentSlide, behavior: "smooth", });
  }, [currentSlide]);

  return (
    <FullScreenModal active={active} ctaIsDisabled={isGenerating} setActive={handleClose} submit={handleSubmit}>
      <div className={styles.slider}>
        <div className={styles.inner}>
          <motion.div {...swipeHandlers} className={styles.slides} ref={container}>
            {/* SLIDES ARE BEING GENERATED */}
            {isGenerating && slides && slides.length === 0 && (
              <div className={styles.slide}>
                <motion.div
                  key={"placeholder"}
                  className={styles.media}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className={styles.animatedGradient}>
                    <img src={imageGenerating} alt="image" />
                  </div>
                </motion.div>
              </div>
            )}

            {/* SLIDES ARE NOT READY */}
            {!isGenerating && slides && slides.length === 0 && (
              <div className={cn(styles.slide)}>
                <div className={cn([styles.media, "active"])}></div>
              </div>
            )}

            {/* SLIDES ARE READY */}
            {!isGenerating && slides && slides.length > 0 && (
              <>
                {slides.slice(0, -1).map((slide, index) => (
                  <div key={index} className={styles.slide}>
                    <motion.div
                      key={slide.heading}
                      className={cn(styles.media, { active: index === currentSlide, })}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <img src={slide.image} alt={slide.title} />

                      {index === currentSlide && (
                        <div className={styles.checkIcon}>
                          <span>
                            <Check color="#fff" />
                          </span>
                        </div>
                      )}
                    </motion.div>
                  </div>
                ))}

                <div key={slides.length} className={styles.slide}>
                  <motion.div
                    className={cn(styles.media, { active: currentSlide === slides.length - 1 || (slides.length === 0 && !isGenerating), })}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img src={imageGenerate} alt="Generate" />
                  </motion.div>
                </div>
              </>
            )}
          </motion.div>

          <div className={styles.dots}>
            <Dots currentSlide={currentSlide} slides={slides} showPlaceholder={true} />
          </div>
        </div>

        <div className={styles.body}>
          <div className={cn([ styles.prompt, isGenerating ? styles.prompt__loading : "", ])}>
            <Formik initialValues={{ prompt: "", }} onSubmit={async (values) => handlePromptSubmit(values)} validationSchema={PromptTextSchema}>
              {({ errors, touched, handleChange }) => (
                <Form>
                  <FormPromptTextarea
                    id="prompt"
                    title="Type your prompt here"
                    errors={errors}
                    touched={touched}
                    labeled="false"
                    handleChange={handleChange}
                    customChangeCallback={null}
                  />

                  <button className={cn([styles.prompt__submit, "transition-all"])} disabled={errors["prompt"] && touched["prompt"]} type="submit">
                    <span>
                      <ArrowRight color="#fff" />
                    </span>
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </FullScreenModal>
  );
};

export default GenerateImageModal;
