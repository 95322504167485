import React from "react";

const SaveButton = ({ handleSubmit }) => {
  return (
    <div className="btn-container">
      {/* className={cn(" hover-gradient transition-all", styles.cta)} */}
      <button className="border-gradient-gray50 transition-all cta" onClick={handleSubmit} type="submit">
        <span className="text">
          Save Changes
        </span>
      </button>
    </div>
  );
};

export default SaveButton;
