import React, { useEffect, useState } from "react";
import styles from "assets/styles/modules/UserPage.module.scss";
import cn from "classnames";
import Chevron from "assets/icons/Chevron";
import ArrowRightAngle from "assets/icons/ArrowRightAngle";
import { useNavigate, useParams } from "react-router-dom";
import useCustomer from "lib/hooks/useCustomer";
import moment from "moment";
import SearchFilter from "components/SearchFilter";
import { motion } from "framer-motion";

const Header = ({ data }) => {
  return (
    <div className={styles.header__container}>
      <a className={cn(["font-semibold", styles.header__back])} href="/feed">
        <span>
          <Chevron />
        </span>
        Feed
      </a>
      <div className={styles.header__avatar}>
        <img src={data.avatar} alt="Avatar" />
      </div>
      <h2 className={cn(["sp1 font-semibold", styles.header__heading])}>
        {data.username}
      </h2>
      {data.bio && (
        <div className={cn(["sp1", styles.header__description])}>
          {data.bio}
        </div>
      )}
      {data.ctaTitle && (
        <a
          className={cn("transition-all", [styles.header__cta])}
          href={data.ctaUrl}
          target="_blank"
        >
          {data.ctaTitle}
        </a>
      )}
    </div>
  );
};

const SpaceListItem = ({ data }) => {
  const date = data?.createdAt ? moment(data.createdAt).format("LL") : null;

  return (
    <a href={`/spaces/${data._id}`}>
      <div className={styles.spaceListItem__container}>
        <div className={styles.spaceListItem__icon}>
          <ArrowRightAngle color="#2f2f2f" />
        </div>
        <div className={styles.spaceListItem__img}>
          {data.thumbnail?.outputUrl ? (
            <img src={data.thumbnail.outputUrl} alt="image" />
          ) : (
            <div className={styles.spaceListItem__placeholder}></div>
          )}
        </div>
        <div className={styles.spaceListItem__text}>
          <h3
            className={cn(["sp1 font-semibold", styles.spaceListItem__heading])}
          >
            {data.title}
          </h3>
          <div className={styles.spaceListItem__info}>
            <div className="sp2">{data.description}</div>
            {date && <div className="sp2">{date}</div>}
          </div>
        </div>
      </div>
    </a>
  );
};

const ShowUserPage = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const user = useCustomer();
  const [userData, setUserData] = useState(null);
  const [spaces, setSpaces] = useState(null);
  const [filteredData, setFilteredData] = useState(spaces);

  useEffect(() => {
    user.getSpaces(userId).then((data) => {
      setUserData(data.user);
      setSpaces(data.spaces);
    });
  }, [userId, navigate]);

  if (!userData) return null;

  return (
    <section className="main scroll">
      <div className={styles.container}>
        <Header data={userData} />
        <SearchFilter data={spaces} setData={setFilteredData} />
        <div>
          {filteredData?.map((space, i) => (
            <motion.div 
              key={space._id} 
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: i * 0.2 }}
            >
              <SpaceListItem key={space._id} data={space} />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ShowUserPage;
