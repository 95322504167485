import React from 'react';
import styles from 'assets/styles/modules/FormFieldInfo.module.scss';
import Info from 'assets/icons/Info';

const FormFieldInfo = ({title, text, icon, iconAction}) => {
  return (
    <span>
      <div className="sp1 font-semibold">{title}</div>

      <div className={`${styles.card} ${icon ? styles.hasIcon : ''}`}>
        <div>
          <div>{text}</div>
        </div>
        {icon && (
          <div className={styles.icon}>
            <Info />
          </div>
        )}
      </div>
    </span>
  );
};

export default FormFieldInfo;
