const Edit = ({color}) => {
  console.log(color)
  return (
    <svg viewBox='0 0 14.5 14.5'>
      <path
        d='m10.62.22c.29-.29.77-.29,1.06,0l2.6,2.6c.14.14.22.33.22.53s-.08.39-.22.53L5.18,12.98c-.08.08-.18.14-.29.18l-3.9,1.3c-.27.09-.57.02-.77-.18-.2-.2-.27-.5-.18-.77l1.3-3.9c.04-.11.1-.21.18-.29L10.62.22Zm1.36,3.84c.39-.39.39-1.02,0-1.41l-.13-.13c-.39-.39-1.02-.39-1.41,0l-7.57,7.57c-.11.11-.19.24-.24.39l-.06.19c-.26.78.48,1.53,1.26,1.26l.19-.06c.15-.05.28-.13.39-.24l7.57-7.57Z'
        fill={color || '#1c1c1c'}
        fillRule='evenodd'
      />
    </svg>
  );
};

export default Edit;
