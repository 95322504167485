import styles from 'assets/styles/modules/DashboardCardIframe.module.scss';
import ProjectIframe from 'components/common/Project/ProjectIframe';

const DashboardCardIframe = ({experiment, rowIndex}) => {
  return (
    <div className={styles.container} id={`dashboard-card-${experiment._id}`}>
      <div className={styles.aligner}>
        <ProjectIframe experiment={experiment} rowIndex={rowIndex} />
      </div>
    </div>
  );
};

export default DashboardCardIframe;
