const Wand = ({color}) => {
  return (
    <svg viewBox="0 0 15.9 15.95">
      <path
        d="m10.54,3.34l-3.27,2.95h0S0,12.86,0,12.86l3.43,3.1L13.97,6.44l-3.43-3.1ZM2.09,12.86l6.23-5.62,1.34,1.21-6.23,5.62-1.34-1.21ZM5.85,1.3c-.05-.04-.07-.11-.05-.17.02-.06.08-.1.15-.11l1-.11.42-.83c.03-.06.09-.09.16-.09s.13.04.16.09l.42.83,1,.11c.07,0,.13.05.15.11.02.06,0,.12-.05.17l-.74.62.2.89c.01.06-.01.12-.07.16-.06.04-.13.04-.19.01l-.88-.45-.88.45c-.06.03-.13.03-.19-.01-.06-.04-.08-.1-.07-.16l.2-.89-.74-.62Zm8.99,7.95c.05,0,.09.04.11.08.02.04,0,.09-.04.12l-.56.46.15.67s-.01.09-.05.12c-.04.03-.1.03-.14,0l-.66-.33-.66.33s-.1.02-.14,0c-.04-.03-.06-.07-.05-.12l.15-.67-.56-.46s-.05-.08-.04-.12c.02-.04.06-.08.11-.08l.75-.08.32-.62s.07-.07.12-.07.1.03.12.07l.32.62.75.08Zm1.02-5.74l-.56.46.15.67s-.01.09-.05.12c-.04.03-.1.03-.14,0l-.66-.33-.66.33s-.1.02-.14,0-.06-.07-.05-.12l.15-.67-.56-.46s-.05-.08-.04-.12c.02-.04.06-.08.11-.08l.75-.08.32-.62s.07-.07.12-.07.1.03.12.07l.32.62.75.08c.05,0,.09.04.11.08.02.04,0,.09-.04.12Z"
        fill={color ? color : '#262626'}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Wand;
