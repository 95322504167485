const Crop = ({color}) => {
  return (
    <svg viewBox="0 0 17.7 17.7">
      <path
        d="m12.85,16.85V4.85H.85"
        fill="none"
        stroke={color ? color : '#fff'}
        strokeLinecap="round"
        strokeWidth="1.7"
      />
      <path
        d="m4.85.85v12h12"
        fill="none"
        stroke={color ? color : '#fff'}
        strokeLinecap="round"
        strokeWidth="1.7"
      />
    </svg>
  );
};

export default Crop;
