import {useCallback, useEffect, useState} from 'react';
import FullScreenModal from '../FullScreenModal';
import styles from 'assets/styles/modules/ImageEditModal.module.scss';
import Cropper from 'react-easy-crop';
import Wand from 'assets/icons/Wand';
import cn from 'classnames';
import Crop from 'assets/icons/Crop';

import useExperimentSections from 'lib/hooks/useExperimentSections';

const ImageEditModal = ({ img, setTempImage, active, handleClose, rowId, }) => {
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [aspect, setAspect] = useState(0);
  const [croppedImage, setCroppedImage] = useState(null);
  const sections = useExperimentSections();

  const handleSubmit = async () => {
    // get ID of previous image for replacement
    const urlSplitBySlash = img.split('/');
    const originalImageId = urlSplitBySlash[urlSplitBySlash.length - 1].split('.')[0];

    // upload the image
    const response = await sections.replaceImage(rowId, originalImageId, croppedImage, 'image/png', );

    setTempImage(croppedImage);
    handleClose();
  };

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      if (img && zoom > 1) {
        // Creating new Image object with the name img
        const newImg = new Image();
        // Setting cross origin value to anonymous
        newImg.crossOrigin = 'anonymous';
        newImg.alt = 'Test';
        // The Image URL is been set to the
        // src property of the image
        // Query string is needed to prevent caching in Chrome, every other browser is fine
        newImg.src = img + '?cache=false';

        newImg.onload = () => {
          const canvas = document.createElement('canvas');
          const scaleX = newImg.naturalWidth / newImg.width;
          const scaleY = newImg.naturalHeight / newImg.height;
          canvas.width = croppedAreaPixels.width;
          canvas.height = croppedAreaPixels.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(
            newImg,
            croppedAreaPixels.x * scaleX,
            croppedAreaPixels.y * scaleY,
            croppedAreaPixels.width * scaleX,
            croppedAreaPixels.height * scaleY,
            0,
            0,
            croppedAreaPixels.width,
            croppedAreaPixels.height,
          );
          const base64Image = canvas.toDataURL('image/png');
          setCroppedImage(base64Image);
        };
      }
    },
    [zoom],
  );

  useEffect(() => {
    if (active) {
      const imgElement = new Image();
      imgElement.src = img;
      imgElement.addEventListener('load', () => {
        const aspectRatio = imgElement.naturalWidth / imgElement.naturalHeight;
        setAspect(aspectRatio);
      });
    }
  }, [active]);

  return (
    <FullScreenModal active={active} setActive={handleClose} submit={handleSubmit}>
      <div className={styles.container}>
        <div className={styles.img}>
          <div className={styles.cropper}>
            <Cropper
              aspect={aspect}
              crop={crop}
              image={img}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              zoom={zoom}
              zoomWithScroll={true}
            />
          </div>
        </div>

        <div className={styles.footer}>
          <button onClick={() => {}}>
            <span
              className={cn([
                'transition-all',
                styles.circleIcon,
                styles.disabled,
              ])}
            >
              <span className={cn(['w-16', styles.icon])}>
                <Wand color="#ffffff" />
              </span>
            </span>

            <span className="sp2 font-semibold">Auto</span>
          </button>

          <button onClick={() => setTempImage(croppedImage)}>
            <span className={cn(['transition-all', styles.circleIcon, styles.active, ])}>
              <span className={cn(['w-16', styles.icon])}>
                <Crop />
              </span>
            </span>

            <span className="sp2 font-semibold">Crop</span>
          </button>
        </div>
      </div>
    </FullScreenModal>
  );
};

export default ImageEditModal;
