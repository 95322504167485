import React from "react";
import styles from "assets/styles/modules/FormEditImage.module.scss";
import FormImageAdd from "./FormImageAdd";
import cn from "classnames";

const FormEditImageRepeater = ({
  errors,
  id,
  img,
  multiple,
  openGenerateImageModal,
  openImageEditModal,
  rowId,
  setFieldValue,
  setTempImage,
  slot,
  touched,
}) => {
  return (
    <div className={cn(styles.container, { [styles.multiple]: multiple })}>
      <FormImageAdd
        errors={errors}
        id={id}
        img={img || null}
        rowId={rowId}
        setFieldValue={setFieldValue}
        setTempImage={setTempImage}
        slot={slot}
        title=""
        touched={touched}
        openImageEditModal={openImageEditModal}
        openGenerateImageModal={openGenerateImageModal}
      />
    </div>
  );
};

export default FormEditImageRepeater;
