const useExperiments = (userId) => {
  const deleteOne = async (experimentId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/experiments/${experimentId}`,
        {
          headers: { Authorization: userId, 'Content-Type': 'application/json', },
          method: 'DELETE',
        },
      );

      if (!response.ok) {
        throw new Error();
      }

    } catch (error) {
      console.log(error);
    }
  }

  const fetchExperiment = async (experimentId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/experiments/${experimentId}`,
        {
          headers: {'Content-Type': 'application/json'},
          method: 'GET',
        },
      );

      if (!response.ok) {
        throw new Error();
      }

      const data = await response.json();

      return data.experiment;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserExperiments = async (recordLimit) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/experiments?recordLimit=${recordLimit}`,
        {
          headers: {
            Authorization: userId,
            'Content-Type': 'application/json',
          },
          method: 'GET',
        },
      );

      const data = await response.json();

      return data.experiments;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserPublishedExperiments = async (recordLimit) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/experiments?recordLimit=${recordLimit}&status=published`,
        {
          headers: {
            Authorization: userId,
            'Content-Type': 'application/json',
          },
          method: 'GET',
        },
      );

      const data = await response.json();

      return data.experiments;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFeedExperiments = async (recordLimit) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/experiments/list?recordLimit=${recordLimit}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'GET',
        },
      );

      const data = await response.json();

      return data.experiments;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserFavorites = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/experiments/favorite?recordLimit=5`,
        {
          headers: {
            Authorization: userId,
            'Content-Type': 'application/json',
          },
          method: 'GET',
        },
      );

      const data = await response.json();

      return data.experiments;
    } catch (error) {
      console.log(error);
    }
  };

  const favorite = async (experimentId) => {
    try {
      const bodyData = JSON.stringify({experimentId});
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/experiments/favorite`,
        {
          body: bodyData,
          headers: {
            Authorization: userId,
            'Content-Type': 'application/json',
          },
          method: 'POST',
        },
      );

      const data = await response.json();

      return data.user;
    } catch (error) {
      localStorage.setItem('message-for-user', error);
    }
  };

  const like = async (experimentId) => {
    try {
      const bodyData = JSON.stringify({experimentId});
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/experiments/like`,
        {
          body: bodyData,
          headers: {
            Authorization: userId,
            'Content-Type': 'application/json',
          },
          method: 'POST',
        },
      );

      const data = await response.json();

      return data.user;
    } catch (error) {
      localStorage.setItem('message-for-user', error);
    }
  };

  const updateExperiment = async (values) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/experiments/${values._id}`,
        {
          body: JSON.stringify(values),
          headers: {
            Authorization: userId,
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        },
      );

      const data = await response.json();

      return data.experiment;
    } catch (error) {
      localStorage.setItem('message-for-user', error);
    }
  };

  const fetchPageContents = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/templates/data/${id}`,
        {
          headers: {
            Authorization: userId,
            'Content-Type': 'application/json',
          },
          method: 'POST',
        },
      );

      const data = await response.json();

      return data;
    } catch (error) {
      // TODO -- find out why this errors going from preview -> edit
      // localStorage.setItem('message-for-user', error); 
    }
  };

  const uploadImage = async (userId, fileName, base64) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/experiments/upload-image`,
        {
          body: JSON.stringify({ base64: base64, fileName: fileName, userId: userId, }),
          headers: { Authorization: userId, 'Content-Type': 'application/json', },
          method: 'POST',
        },
      );

      const data = await response.json();

      return data;
    } catch (error) {
      localStorage.setItem('message-for-user', error);
    }
  };

  return {
    deleteOne,
    favorite,
    fetchExperiment,
    fetchFeedExperiments,
    fetchPageContents,
    fetchUserExperiments,
    fetchUserPublishedExperiments,
    fetchUserFavorites,
    like,
    updateExperiment,
    uploadImage,
  };
};

export default useExperiments;
