import styles from 'assets/styles/modules/Modal.module.scss';
import { useEffect, useRef, useState } from 'react';
import ModalHeading from '../ModalHeading';
import ModalOptions from '../ModalOptions';
import ModalOptionsLine from '../ModalOptionsLine';
import FormField from '../../Forms/FormField';
import { Form, Formik } from 'formik';
import { SpaceHomeImageSchema, SpaceHomeTitleSchema, SpaceTextSchema, } from 'schemas';
import useExperimentSections from 'lib/hooks/useExperimentSections';
import Modal from '../Modal';
import ModalLevel from '../ModalLevel';
import FormTextarea from 'components/common/Forms/FormTextarea';
import GenerateButton from 'components/common/Buttons/GenerateButton';
import FormEditImage from '../../Forms/FormEditImage';
import { AnimatePresence } from 'framer-motion';
import { useStore } from 'state/store';
import { resizeFile } from 'utilities/resizeFile';
import useScrollBlock from 'lib/hooks/useScrollBlock';

const VideoModal = ({
  active,
  setActive,
  content,
  setTempImage,
  setTempTitle,
  setTempText,
}) => {
  const refTitle = useRef(null);
  const refImage = useRef(null);
  const refText = useRef(null);
  const [headerTitle, setHeaderTitle] = useState(null);
  const [activeLevel, setActiveLevel] = useState('');
  const sections = useExperimentSections();
  const userData = useStore((state) => state.user);

  useScrollBlock(active);

  const activateSubLevel = (levelName) => {
    setActiveLevel(levelName);
  };

  const handleBack = () => {
    setActiveLevel('');
    setTempImage(null);
  };

  const handleHeadingClose = () => {
    // Just close the modal
    setActive(false);
    setTempImage(null);
  };

  const handleHeadingSubmit = () => {
    if (activeLevel === 'Video Title') {
      submitTitleForm();
    } else if (activeLevel === 'Video') {
      submitImageForm();
    } else if (activeLevel === 'Video Text') {
      submitTextForm();
    }
    setTimeout(() => {
      setActiveLevel('');
      setActive(false);
    }, 1000);
  };

  const submitTitleForm = () => {
    if (refTitle.current) refTitle.current.click();
  };

  const submitImageForm = () => {
    if (refImage.current) refImage.current.click();
  };

  const submitTextForm = () => {
    if (refText.current) refText.current.click();
  };

  useEffect(() => {
    if (activeLevel === '') {
      setHeaderTitle('Video');
    } else {
      setHeaderTitle(activeLevel);
    }
  }, [activeLevel]);

  return (
    <AnimatePresence>
      {active && (
        <Modal handleClose={handleHeadingClose}>
          <ModalHeading
            title={headerTitle}
            levelIsActive={activeLevel !== ''}
            handleHeadingClose={handleHeadingClose}
            handleHeadingSubmit={handleHeadingSubmit}
            handleBack={handleBack}
          />

          <div className={styles.body}>
            <ModalOptions active={activeLevel === ''}>
              <ModalOptionsLine
                title={'Title'}
                handleClick={() => activateSubLevel('Video Title')}
              />

              <ModalOptionsLine
                title={'Image'}
                handleClick={() => activateSubLevel('Video')}
              />

              <ModalOptionsLine
                title={'Text'}
                handleClick={() => activateSubLevel('Video Text')}
              />
            </ModalOptions>

            {/* Title Level */}
            <ModalLevel active={activeLevel === 'Video Title'} columns={1}>
              <div className='mb-32'>
                <Formik
                  initialValues={{ title: content?.texts[0] || 'Video', }}
                  validationSchema={SpaceHomeTitleSchema}
                  onSubmit={async (values) => {
                    const updatedTexts = content.texts;
                    updatedTexts[0] = values.title;
                    const response = await sections.updateTexts(
                      userData.userId,
                      content.rowId,
                      updatedTexts,
                    );
                    if (response?.success) setActive(false);
                  }}
                >
                  {({ errors, touched, handleChange }) => (
                    <Form>
                      <FormField
                        id='title'
                        title=''
                        type='text'
                        errors={errors}
                        touched={touched}
                        labeled='false'
                        handleChange={handleChange}
                        customChangeCallback={setTempTitle}
                      />

                      <button ref={refTitle} style={{ display: 'none' }} type='submit'>
                        <span>Save</span>
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>

              <GenerateButton />
            </ModalLevel>

            {/* Image Level */}
            <ModalLevel active={activeLevel === 'Video'} columns={2}>
              <Formik
                initialValues={{ file: null, }}
                validationSchema={SpaceHomeImageSchema}
                onSubmit={async (values) => {
                  const base64 = await resizeFile(values.file);
                  const response = await sections.updateImages(
                    userData.userId,
                    content.rowId, // sectionId
                    [base64], // image base64 format
                    values.file.type, // contentType
                  );
                  if (response?.success) setActive(false);
                }}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form>
                    <FormEditImage
                      errors={errors}
                      touched={touched}
                      img={content.images[0] || null}
                      rowId={ content.rowId } 
                      setTempImage={setTempImage}
                      setFieldValue={setFieldValue}
                    />

                    <button ref={refImage} style={{ display: 'none' }} type='submit'>
                      <span>Save</span>
                    </button>
                  </Form>
                )}
              </Formik>
            </ModalLevel>

            {/* Text Level */}
            <ModalLevel active={activeLevel === 'Video Text'} columns={1}>
              <Formik
                initialValues={{ text: content?.texts[1] || '', }}
                validationSchema={SpaceTextSchema}
                onSubmit={async (values) => {
                  const updatedTexts = content.texts;
                  updatedTexts[1] = values.text;
                  const response = await sections.updateTexts(
                    userData.userId,
                    content.rowId,
                    updatedTexts,
                  );
                  if (response?.success) setActive(false);
                }}
              >
                {({ errors, touched, handleChange }) => (
                  <Form>
                    <FormTextarea
                      id='text'
                      errors={errors}
                      touched={touched}
                      labeled={false}
                      handleChange={handleChange}
                      customChangeCallback={setTempText}
                    />

                    <button ref={refText} style={{ display: 'none' }} type='submit'>
                      <span>Save</span>
                    </button>
                  </Form>
                )}
              </Formik>

              <GenerateButton />
            </ModalLevel>
          </div>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default VideoModal;
