import Plus from 'assets/icons/Plus';
import React, { useRef, useState } from 'react';
import { resizeFile } from 'utilities/resizeFile';

import useExperimentSections from 'lib/hooks/useExperimentSections';

const FormImageAddMultiple = ({
  id,
  title,
  errors,
  touched,
  labeled,
  img,
  rowId,
  setFieldValue,
  setTempImages,
  tempImages,
  slot,
}) => {
  const file = useRef(null);
  const [thumb, setThumb] = useState(null);
  const sections = useExperimentSections();

  const handleImageUpload = async (e) => {
    console.log(e.currentTarget.files[0])

    // if the user canceled upload, do nothing
    if (!e?.currentTarget?.files || !e.currentTarget.files[0]) return;
    const file = e.currentTarget.files[0];

    // get ID of previous image for replacement
    const urlSplitBySlash = img.split('/');
    const originalImageId = urlSplitBySlash[urlSplitBySlash.length - 1].split('.')[0];

    // base64 the image for upload
    const base64 = await resizeFile(file, 300, 500, 1000, 1000);

    // upload the image
    const response = await sections.replaceImage(rowId, originalImageId, base64, file.type);

    setFieldValue(`files[${slot}]`, file);
    setThumb(response.outputUrl);

    const newImages = tempImages.map((previousImage, i) => i === slot ? response.outputUrl : previousImage);
    setTempImages(newImages);      
  };

  const openImageDialog = () => {
    file.current.click();
  };

  return (
    <div className='inputFile inputFile--image'>
      <label className={ `inputFile_label ${labeled ? '' : 'visually-hidden'}` } htmlFor={ id }>
        { title }
      </label>

      <input accept='image/*' className='inputFile__input' id={ `files[${slot}]` } name={ `files[${slot}]` } onChange={ handleImageUpload } ref={ file } type='file' />

      {!(thumb || img) &&
        <div className='inputFile__icon w-16'>
          <Plus color='#002534' />
        </div>
      }

      {errors[id] && touched[id] &&
        <div className='sp1 formRow_message'>{ errors[id] }</div>
      }

      <div className='inputFile__thumbnail' onClick={openImageDialog}>
        {(thumb || img) &&
          <img alt='image' className='inputFile__thumbnail__img' id={ `inputFile__thumbnail__${thumb || img}` } src={ thumb || img } />
        }
      </div>
    </div>
  );
};

export default FormImageAddMultiple;
