import {useEffect, useState} from 'react';
import {useStore} from 'state/store';

import useExperiments from 'lib/hooks/useExperiments';

import styles from 'assets/styles/modules/ProjectItem.module.scss';

import ProjectIframe from './ProjectIframe';
import ProjectItemActions from './ProjectItemActions';
import ProjectItemHeader from './ProjectItemHeader';

const ProjectItem = ({experiment, rowIndex}) => {
  const [experimentData, setExperimentData] = useState(null);
  const userData = useStore((state) => state.user);
  const experiments = useExperiments(userData.userId);

  useEffect(() => {
    if (experimentData === null) {
      experiments.fetchExperiment(experiment._id).then((data) => {
        setExperimentData(data);
      });
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.card} key={experiment.previewId}>
        <div className={styles.iframeContainer}>
          <div className={styles.iframeAligner}>
            <ProjectIframe experiment={experiment} rowIndex={rowIndex} />
          </div>
        </div>
      </div>

      <div className={styles.actions}>
        <ProjectItemActions
          experimentData={experimentData}
          variant={'outlineInverted'}
          experimentId={experiment._id}
        />
      </div>
      <ProjectItemHeader experiment={experimentData} />
    </div>
  );
};

export default ProjectItem;
