import ModalHeading from "../ModalHeading";
import ModalOptions from "../ModalOptions";
import ModalOptionsLine from "../ModalOptionsLine";
import FormField from "../../Forms/FormField";
import { Form, Formik } from "formik";
import { SpaceHomeTitleSchema, SpaceTextSchema } from "schemas";
import Modal from "../Modal";
import ModalLevel from "../ModalLevel";
import FormTextarea from "components/common/Forms/FormTextarea";
import { AnimatePresence } from "framer-motion";
import useScrollBlock from "lib/hooks/useScrollBlock";
import ModalBody from "../ModalBody";
import useSectionModal from "lib/hooks/useSectionModal";
import SaveButton from "../SaveButton";

const TextModal = ({
  active,
  setActive,
  content,
  setTempTitle,
  setTempText,
  tempText,
  tempTitle,
}) => {
  useScrollBlock(active);

  const sectionModal = useSectionModal(
    content,
    setActive,
    null,
    null,
    tempText,
    tempTitle,
    "Text"
  );

  return (
    <AnimatePresence>
      {active && (
        <Modal handleClose={sectionModal.handleHeadingClose}>
          <ModalHeading
            handleBack={sectionModal.handleBack}
            handleHeadingClose={sectionModal.handleHeadingClose}
            handleHeadingSubmit={sectionModal.handleHeadingSubmit}
            levelIsActive={sectionModal.activeLevel !== ""}
            title={sectionModal.headerTitle}
          />

          <ModalBody activeLevel={sectionModal.activeLevel}>
            <ModalOptions active={sectionModal.activeLevel === ""}>
              <ModalOptionsLine
                handleClick={() => sectionModal.activateSubLevel("Title")}
                title={"Title"}
              />

              <ModalOptionsLine
                handleClick={() => sectionModal.activateSubLevel("Text")}
                title={"Text"}
              />
            </ModalOptions>

            {/* Title Level */}
            <ModalLevel
              active={sectionModal.activeLevel === "Title"}
              columns={1}
            >
              <div>
                <Formik
                  enableReinitialize={true}
                  initialTouched={{ title: true }}
                  initialValues={{
                    title: tempTitle !== null ? tempTitle : content?.texts[0],
                  }}
                  validationSchema={SpaceHomeTitleSchema}
                  onSubmit={sectionModal.handleTitleEditGenerate}
                >
                  {({ errors, touched, handleChange, submitForm }) => (
                    <>
                      <Form>
                        <FormField
                          customChangeCallback={setTempTitle}
                          errors={errors}
                          handleChange={handleChange}
                          id="title"
                          labeled="false"
                          title=""
                          touched={touched}
                          type="text"
                        />
                      </Form>
                      <SaveButton handleSubmit={submitForm} />
                    </>
                  )}
                </Formik>
              </div>
            </ModalLevel>

            {/* Text Level */}
            <ModalLevel active={sectionModal.activeLevel === "Text"} columns={1}>
              <Formik
                enableReinitialize={true}
                initialTouched={{ text: true }}
                initialValues={{
                  text: tempText !== null ? tempText : content?.texts[1],
                }}
                validationSchema={SpaceTextSchema}
                onSubmit={sectionModal.handleTextEditGenerate}
              >
                {({ errors, touched, handleChange, submitForm }) => (
                  <>
                    <Form>
                      <FormTextarea
                        customChangeCallback={setTempText}
                        errors={errors}
                        handleChange={handleChange}
                        id="text"
                        labeled={false}
                        touched={touched}
                      />
                    </Form>
                    <SaveButton handleSubmit={submitForm} />
                  </>
                )}
              </Formik>
            </ModalLevel>
          </ModalBody>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default TextModal;
