import globalSectionStyles from 'assets/styles/modules/Section.module.scss';
import styles from 'assets/styles/modules/LinkSection.module.scss';
import Bin from 'assets/icons/Bin';
import CircleIcon from 'assets/icons/CircleIcon';
import Plus from 'assets/icons/Plus';
import LinkModal from 'components/common/Modal/Link/LinkModal';
import {useState} from 'react';
import EditButton from './ui/EditButton';
import useSwipeHandlers from 'lib/hooks/useSwipeHandlers';
import {motion} from 'framer-motion';
import {spring} from 'utilities/animations';
import useSelectTemplate from 'lib/hooks/useSelectTemplate';
import ArrowRightAngle from 'assets/icons/ArrowRightAngle';

const LinkSection = ({content, isShowPage, openEdit, removeSection}) => {
  const [active, setActive] = useState(false);
  const [tempTitle, setTempTitle] = useState(null);
  const {swipeHandlers, layout} = useSwipeHandlers(content?.variationNumber || 1);
  useSelectTemplate(content, layout === 0 ? 1 : layout);

  return (
    <>
      <section {...(isShowPage ? [] : swipeHandlers)} className={globalSectionStyles.container} draggable="false" style={{userSelect: 'none'}}>
        <LinkModal active={active} content={content} setActive={setActive} setTempTitle={setTempTitle} title="Button" />

        {openEdit && (
          <EditButton openModal={setActive} position="bottomRightNoImage" />
        )}

        <div
          className={`${isShowPage ? globalSectionStyles.contentFullPageView : globalSectionStyles.contentEditView} ${styles.content}`}
          data-layout={layout === 0 ? 1 : layout}
        >
          <motion.div className={styles.link} layout transition={spring}>
            {isShowPage ? (
              <a className={styles.inner}
                href={
                  `${content?.texts?.[2] === 'email' ? 'mailto:'
                        : content?.texts?.[2] === 'phone' ? 'tel:'
                          : ''
                  }` + content?.texts[1]
                }
                target="_blank"
              >
                {tempTitle ? tempTitle : content?.texts[0] ? content?.texts[0] : 'Lorem Ipsum dolor consec'}
              </a>
            ) : (
              <span className={styles.inner}>
                {tempTitle ? tempTitle : content?.texts[0] ? content?.texts[0] : 'Lorem Ipsum dolor consec'}
              </span>
            )}
            <span className={styles.icon}>
              <ArrowRightAngle color="#2f2f2f" />
            </span>
          </motion.div>
        </div>

        {openEdit && (
          <div className={globalSectionStyles.delete} onClick={() => removeSection(content.rowId)}>
            <CircleIcon variant={'filledInverted'} iconWidth={'w-14'}>
              <Bin />
            </CircleIcon>
          </div>
        )}

        {openEdit && (
          <div className={globalSectionStyles.addSection} onClick={() => openEdit({status: true, afterPosition: content.position}) }>
            <CircleIcon iconWidth={'w-12'}>
              <Plus />
            </CircleIcon>
          </div>
        )}
      </section>

      {openEdit && <div className={globalSectionStyles.divider}></div>}
    </>
  );
};

export default LinkSection;
