
import ModalHeading from "../ModalHeading";
import ModalOptions from "../ModalOptions";
import ModalOptionsLine from "../ModalOptionsLine";
import { Form, Formik } from "formik";
import { SpaceTextSchema } from "schemas";
import Modal from "../Modal";
import ModalLevel from "../ModalLevel";
import FormTextarea from "components/common/Forms/FormTextarea";
import { AnimatePresence } from "framer-motion";
import useScrollBlock from "lib/hooks/useScrollBlock";
import ModalBody from "../ModalBody";
import SaveButton from "../SaveButton";
import useSectionModal from "lib/hooks/useSectionModal";

const QuoteModal = ({
  active,
  setActive,
  title,
  content,
  columns,
  setTempText,
  tempText,
}) => {
  useScrollBlock(active);

  const sectionModal = useSectionModal(
    content,
    setActive,
    null,
    null,
    null,
    tempText,
    "Quote"
  );

  return (
    <AnimatePresence>
      {active && (
        <Modal handleClose={sectionModal.handleHeadingClose}>
          <ModalHeading
            handleBack={sectionModal.handleBack}
            handleHeadingClose={sectionModal.handleHeadingClose}
            handleHeadingSubmit={sectionModal.handleHeadingSubmit}
            levelIsActive={sectionModal.activeLevel === "Text"}
            title={sectionModal.headerTitle}
          />

          <ModalBody activeLevel={sectionModal.activeLevel === "Text"}>
            <ModalOptions active={sectionModal.activeLevel !== "Text"}>
              <ModalOptionsLine
                title={"Text"}
                handleClick={() => sectionModal.activateSubLevel("Text")}
              />
            </ModalOptions>

            <ModalLevel
              active={sectionModal.activeLevel === "Text"}
              columns={columns}
            >
              <Formik
                enableReinitialize={true}
                initialTouched={{ text: true }}
                initialValues={{
                  text: tempText !== null ? tempText : content?.texts[0],
                }}
                validationSchema={SpaceTextSchema}
                onSubmit={sectionModal.handleTitleEditGenerate}
              >
                {({ errors, touched, handleChange, submitForm }) => (
                  <>
                    <Form>
                      <FormTextarea
                        customChangeCallback={setTempText}
                        errors={errors}
                        handleChange={handleChange}
                        id="text"
                        labeled={false}
                        title="Text"
                        touched={touched}
                        type="text"
                      />
                    </Form>
                    <SaveButton handleSubmit={submitForm} />
                  </>
                )}
              </Formik>
            </ModalLevel>
          </ModalBody>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default QuoteModal;
