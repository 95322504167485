import {useNavigate} from 'react-router-dom';
import useCustomer from 'lib/hooks/useCustomer';
import useRequireUnauthenticated from 'lib/hooks/useRequireUnauthenticated';
import {Formik, Form} from 'formik';
import {CreateUserSchema} from 'schemas';
import FormField from 'components/common/Forms/FormField';
import styles from 'assets/styles/modules/SignUp.module.scss';
import ArrowRight from 'assets/icons/ArrowRight';
import cn from 'classnames';
import {useRef, useState} from 'react';

const SignUp = ({user, hidden, setTwoFactor}) => {
  if (hidden) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        inviteCode: '',
        password: '',
        phoneNumber: '',
        username: '',
      }}
      validationSchema={CreateUserSchema}
      onSubmit={async (values) => {
        const response = await user.createUser(values);
        if (response?.success) {
          setTwoFactor(true);
        }
      }}
    >
      {({errors, touched, handleChange, submitForm}) => (
        <Form>
          <section className="main">
            <div className="page_content p-signup">
              <h3 className="st2">Sign Up</h3>

              <p className="sp1">
                You will use your phone number and password to log into your
                Paralllel account and save your work.
              </p>

              <div className="form">
                <div className="formRow">
                  <FormField
                    id="inviteCode"
                    title="Invite Code"
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                  />
                </div>
                <div className="formRow">
                  <FormField
                    id="phoneNumber"
                    title="Phone Number"
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                  />
                </div>
                <div className="formRow">
                  <FormField
                    id="username"
                    title="Username"
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                  />
                </div>
                <div className="formRow">
                  <FormField
                    id="password"
                    title="Password"
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                  />
                </div>
              </div>

              <p className="sp3">
                You agree to receive automated text messages from us, even if
                your mobile number is listed on any do-not-call registry. You
                also agree to our Terms of Use and acknowledge our Privacy
                Policy. Message frequency varies. <br />
                Message and data rates may apply. Text STOP to cancel.
              </p>
            </div>

            <div className="page_actions">
              <button
                className="button button--filled button--sized"
                type="submit"
                onClick={submitForm}
              >
                <span>Send</span>
              </button>
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
};

const TwoFactor = ({active, setTwoFactor}) => {
  const pincode1 = useRef(null);
  const pincode2 = useRef(null);
  const pincode3 = useRef(null);
  const pincode4 = useRef(null);
  const pincode5 = useRef(null);
  const pincode6 = useRef(null);
  const navigate = useNavigate();

  if (!active) {
    return null;
  }

  return (
    <section className="main">
      <div className="page_content p-signup">
        <div className="mb-40">
          <h3 className="st2">
            We've sent you a security code. Please type it here.
          </h3>
        </div>

        <Formik
          onSubmit={async (values) => {
            // Send the code to the server
            setTwoFactor(false);
            //navigate(`/spaces/create`);
          }}
        >
          {({values, errors, touched, handleChange, submitForm}) => (
            <Form>
              <div className={styles.twoFactorForm}>
                <input
                  type="tel"
                  id="pincode1"
                  name="pincode1"
                  maxlength="1"
                  pattern="[\d]*"
                  tabindex="1"
                  placeholder="X"
                  autocomplete="off"
                  className={styles.twoFactorInput}
                  ref={pincode1}
                  onKeyUp={(e) => {
                    if(e.code === 'Backspace') {
                      return;
                    } else {
                      pincode2.current.focus();
                    }
                  }}
                ></input>
                <input
                  ref={pincode2}
                  type="tel"
                  id="pincode2"
                  name="pincode2"
                  maxlength="1"
                  pattern="[\d]*"
                  tabindex="1"
                  placeholder="X"
                  autocomplete="off"
                  className={styles.twoFactorInput}
                  onKeyUp={(e) => {
                    if(e.code === 'Backspace') {
                      pincode1.current.focus();
                    } else {
                      pincode3.current.focus();
                    }
                  }}
                ></input>
                <input
                  type="tel"
                  id="pincode-3"
                  name="pincode-3"
                  maxlength="1"
                  pattern="[\d]*"
                  tabindex="1"
                  placeholder="X"
                  autocomplete="off"
                  className={styles.twoFactorInput}
                  ref={pincode3}
                  onKeyUp={(e) => {
                    if(e.code === 'Backspace') {
                      pincode2.current.focus();
                    } else {
                      pincode4.current.focus();
                    }
                  }}
                ></input>
                <input
                  type="tel"
                  id="pincode-4"
                  name="pincode-4"
                  maxlength="1"
                  pattern="[\d]*"
                  tabindex="1"
                  placeholder="X"
                  autocomplete="off"
                  className={styles.twoFactorInput}
                  ref={pincode4}
                  onKeyUp={(e) => {
                    if(e.code === 'Backspace') {
                      pincode3.current.focus();
                    } else {
                      pincode5.current.focus();
                    }
                  }}
                ></input>
                <input
                  type="tel"
                  id="pincode-5"
                  name="pincode-5"
                  maxlength="1"
                  pattern="[\d]*"
                  tabindex="1"
                  placeholder="X"
                  autocomplete="off"
                  className={styles.twoFactorInput}
                  ref={pincode5}
                  onKeyUp={(e) => {
                    if(e.code === 'Backspace') {
                      pincode4.current.focus();
                    } else {
                      pincode6.current.focus();
                    }
                  }}
                ></input>
                <input
                  type="tel"
                  id="pincode-6"
                  name="pincode-6"
                  maxlength="1"
                  pattern="[\d]*"
                  tabindex="1"
                  placeholder="X"
                  autocomplete="off"
                  className={styles.twoFactorInput}
                  ref={pincode6}
                  onKeyUp={(e) => {
                    if(e.code === 'Backspace') {
                      pincode5.current.focus();
                    } else {
                      return;
                    }
                  }}
                ></input>
              </div>

              <p className="sp0 text-center underline mb-48">
                I didn't get a text message
              </p>

              <p className="sp1 text-center">
                By proceeding, you accept our <br />
                <a className="underline" href="#">
                  Terms of Use
                </a>{' '}
                and{' '}
                <a className="underline" href="#">
                  Privacy Policy
                </a>
                .
              </p>

              <div className={styles.actions}>
                <div className={styles.ctas}>
                  <button
                    className={styles.back}
                    onClick={() => setTwoFactor(false)}
                  >
                    <span>
                      <ArrowRight />
                    </span>
                  </button>
                  <button
                    className={cn([
                      styles.cta,
                      'button button--filled button--sized',
                    ])}
                    type="submit"
                    onClick={submitForm}
                  >
                    <span>Sign Up</span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

function RegistrationPage() {
  // User must be Unauthenticated to view this page
  useRequireUnauthenticated();

  const user = useCustomer();
  const [twoFactor, setTwoFactor] = useState(true);

  return (
    <>
      <SignUp user={user} hidden={twoFactor} setTwoFactor={setTwoFactor} />
      <TwoFactor user={user} active={twoFactor} setTwoFactor={setTwoFactor} />
    </>
  );
}

export default RegistrationPage;
