const CopyLink = ({color}) => {
  return (
    <svg viewBox="0 0 13.57 13.57">
      <path
        d="m2.01,9.08c-.68.68-.68,1.79,0,2.47.68.68,1.79.68,2.47,0l.88-.88c.29-.29.77-.29,1.06,0,.29.29.29.77,0,1.06l-.88.88c-1.27,1.27-3.33,1.27-4.6,0-1.27-1.27-1.27-3.33,0-4.6l2.83-2.83c1.09-1.09,2.76-1.24,4.01-.47.35.22.35.7.06,1-.29.29-.77.27-1.16.13-.62-.23-1.35-.1-1.85.4l-2.83,2.83Zm7.07-7.07l-.88.88c-.29.29-.77.29-1.06,0-.29-.29-.29-.77,0-1.06l.88-.88c1.27-1.27,3.33-1.27,4.6,0,1.27,1.27,1.27,3.33,0,4.6l-2.83,2.83c-1.09,1.09-2.76,1.24-4.01.47-.35-.22-.35-.7-.06-1,.29-.29.77-.27,1.16-.13.62.23,1.35.1,1.85-.4l2.83-2.83c.68-.68.68-1.79,0-2.47-.68-.68-1.79-.68-2.47,0Z"
        fill={color ? color : '#2f2f2f'}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CopyLink;
