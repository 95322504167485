import create from 'zustand';
import { persist } from 'zustand/middleware';

export const useStore = create(
  persist(
    (set, get) => ({
      user: {
        userId: null,
        ctaTitle: null,
        ctaUrl: null,
        email: null,
        username: null,
        phoneNumber: null,
        bio: null,
      },
      setUser: (data) => set(() => ({ user: data })),
    }),
    {
      name: 'paralllel',
    },
  ),
);
