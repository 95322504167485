import { useEffect, useState } from "react";
import useExperimentSections from "./useExperimentSections";
import { useStore } from "state/store";
import { resizeFile } from "utilities/resizeFile";

const useSectionModal = (
  content,
  setActive,
  setTempImage,
  tempImage,
  tempText,
  tempTitle,
  headerText
) => {
  const [headerTitle, setHeaderTitle] = useState(null);
  const [activeLevel, setActiveLevel] = useState("");
  const sections = useExperimentSections();
  const userData = useStore((state) => state.user);
  const [openImageEditModal, setOpenImageEditModal] = useState(false);
  const [openGenerateImageModal, setOpenGenerateImageModal] = useState(false);
  const [openGenerateTextModal, setOpenGenerateTextModal] = useState(false);

  const activateSubLevel = (levelName) => {
    setActiveLevel(levelName);
  };

  const handleBack = () => {
    setActiveLevel("");
    if(setTempImage) setTempImage(null);
  };

  const handleHeadingClose = () => {
    setActive(false);
    if(setTempImage) setTempImage(null);
  };

  const handleOpenImageEditModal = () => {
    setOpenImageEditModal(!openImageEditModal);
  };

  const handleOpenGenerateImageModal = () => {
    setOpenGenerateImageModal(!openGenerateImageModal);
  };

  const handleOpenGenerateTextModal = () => {
    setOpenGenerateTextModal(!openGenerateTextModal);
  };

  const handleImageEditGenerate = async () => {
    if (tempImage === null) return;
    const response = await sections.updateImages(
      userData.userId,
      content.rowId,
      [tempImage],
      "image/png"
    );
    if (response) setActive(false);
  };

  const handleTextEditGenerate = async () => {
    if (tempText === null) return;
    const response = await sections.updateTexts(
      userData.userId,
      content.rowId,
      [content.texts[0], tempText]
    );
    if (response) setActive(false);
  };

  const handleTitleEditGenerate = async () => {
    const response = await sections.updateTexts(
      userData.userId,
      content.rowId,
      [tempTitle, content.texts[1]]
    );
    if (response) setActive(false);
  };

  const handleImageUpload = async (values) => {
    const base64 = await resizeFile(values.file);
    const response = await sections.updateImages(
      userData.userId,
      content.rowId,
      [base64],
      values.file.type
    );
    if (response?.success) setActive(false);
  };

  useEffect(() => {
    if (activeLevel === "") {
      setHeaderTitle(headerText);
    } else {
      setHeaderTitle(activeLevel);
    }
  }, [activeLevel]);

  return {
    headerTitle,
    setHeaderTitle,
    activeLevel,
    setActiveLevel,
    openImageEditModal,
    setOpenImageEditModal,
    openGenerateImageModal,
    setOpenGenerateImageModal,
    openGenerateTextModal,
    setOpenGenerateTextModal,
    activateSubLevel,
    handleBack,
    handleHeadingClose,
    handleOpenImageEditModal,
    handleOpenGenerateImageModal,
    handleOpenGenerateTextModal,
    handleImageEditGenerate,
    handleTextEditGenerate,
    handleTitleEditGenerate,
    handleImageUpload,
  };
};

export default useSectionModal;
