import Search from "assets/icons/Search";
import cn from "classnames";
import styles from "assets/styles/modules/SearchFilter.module.scss";
import { useEffect, useState } from "react";

const SearchFilter = ({ data, setData }) => {
  const [query, setQuery] = useState("");
  const [showIcon, setShowIcon] = useState(true);
  const [sortCriteria, setSortCriteria] = useState("");

  const handleChange = (e) => {
    setQuery(e.target.value);

    if (e.target.value) {
      const filteredData = data.filter(
        (item) =>
          item.title?.toLowerCase().includes(e.target.value.toLowerCase()) ||
          item.description?.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setData(filteredData);
    }
  };

  const sort = (criteria) => {
    switch (criteria) {
      case "recent":
        return () => {
          const sortedData = [...data].sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          setData(sortedData);
          setSortCriteria("recent");
        };
      case "az":
        return () => {
          const sortedData = [...data].sort((a, b) => {
            if (a.title < b.title) {
              return -1;
            }
            if (a.title > b.title) {
              return 1;
            }
            return 0;
          });
          setData(sortedData);
          setSortCriteria("az");
        };
      case "random":
        return () => {
          const sortedData = [...data].sort(() => Math.random() - 0.5);
          setData(sortedData);
          setSortCriteria("random");
        };
      default:
        break;
    }
  };

  useEffect(() => {
    if (query == "") {
      setShowIcon(true);
      setData(data);
    } else {
      setShowIcon(false);
    }
  }, [query]);

  return (
    <div className={styles.SearchFilter__container}>
      <div
        className={cn(["border-gradient-gray50", styles.SearchFilter__input])}
      >
        <input type="search" id="search" onChange={handleChange} />
        {showIcon && (
          <span className={styles.SearchFilter__icon}>
            <Search />
          </span>
        )}
      </div>

      <ul className={styles.SearchFilter__filters}>
        <li>
          <button
            className={cn("sp1 transition-all", [styles.SearchFilter__btn], {
              [styles.active]: sortCriteria === "recent",
            })}
            onClick={sort("recent")}
          >
            Most recent
          </button>
        </li>
        <li>
          <button
            className={cn("sp1 transition-all", [styles.SearchFilter__btn], {
              [styles.active]: sortCriteria === "az",
            })}
            onClick={sort("az")}
          >
            A to Z
          </button>
        </li>
        <li>
          <button
            className={cn("sp1 transition-all", [styles.SearchFilter__btn], {
              [styles.active]: sortCriteria === "random",
            })}
            onClick={sort("random")}
          >
            Random
          </button>
        </li>
      </ul>
    </div>
  );
};

export default SearchFilter;
