import {useState} from 'react';

import cn from 'classnames';
import styles from 'assets/styles/modules/DefaultButton.module.scss';
import Upload from 'assets/icons/Upload';

const UploadButton = ({action, square}) => {
  const [clickDisabled, setClickDisabled] = useState(false);

  const handleClick = () => {
    if (!clickDisabled) action();
    setClickDisabled(true);
  };

  return (
    <span className={ styles.iconWithText }>
      <button className={ styles.iconButton + ' ' + styles.updatedRounded } onClick={handleClick}>
        <div className={cn(['w-12', styles.icon])}>
          <Upload />
        </div>
      </button>
      
      <span>Upload</span>
    </span>
  );
};

export default UploadButton;
