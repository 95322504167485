const Help = ({color}) => {
  return (
    <svg viewBox="0 0 16 16">
      <path
        d="m14.5,8c0,3.59-2.91,6.5-6.5,6.5S1.5,11.59,1.5,8,4.41,1.5,8,1.5s6.5,2.91,6.5,6.5Zm1.5,0c0,4.42-3.58,8-8,8S0,12.42,0,8,3.58,0,8,0s8,3.58,8,8Zm-8.77,1.6c-.07,0-.13-.06-.13-.13,0-1.37.37-1.79,1.03-2.2.48-.3.85-.64.85-1.16,0-.54-.43-.89-.95-.89-.37,0-.72.18-.89.51-.12.24-.33.46-.6.46h-.6c-.28,0-.51-.23-.46-.51.25-1.22,1.3-1.8,2.55-1.8,1.55,0,2.65.8,2.65,2.16,0,.92-.48,1.49-1.21,1.93-.62.37-.89.73-.9,1.49,0,.07-.06.13-.13.13h-1.21Zm1.57,1.56c0,.52-.44.94-.94.94s-.94-.42-.94-.94c0-.51.42-.93.94-.93s.93.42.94.93Z"
        fill={color ? color : '#2f2f2f'}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Help;
