const Generate = ({color}) => {
  return (
    <svg viewBox="0 0 15.89 15.94">
      <path
        d="m12.39,9.44c-.34,0-.61-.27-.64-.61-.3-2.63-2.54-4.67-5.25-4.67-1.87,0-3.52.98-4.46,2.45l.93.93c.1.1.03.28-.11.28h-1.38s-1.27,0-1.27,0h0s-.04,0-.04,0c-.09,0-.16-.07-.16-.16v-2.7c0-.14.18-.22.28-.11l.89.89c1.17-1.69,3.13-2.79,5.34-2.79,3.38,0,6.16,2.59,6.47,5.89.03.34-.24.61-.58.61Zm-11.78,0c.34,0,.61.27.64.61.3,2.63,2.54,4.67,5.25,4.67,1.87,0,3.52-.98,4.46-2.45l-.93-.93c-.1-.1-.03-.28.11-.28h1.38s1.27,0,1.27,0h0s.04,0,.04,0c.09,0,.16.07.16.16v2.7c0,.14-.18.22-.28.11l-.89-.89c-1.17,1.69-3.13,2.79-5.34,2.79C3.12,15.94.33,13.35.03,10.05c-.03-.34.24-.61.58-.61ZM12.17.11c-.03-.07-.1-.11-.17-.11s-.14.04-.17.11l-.45.98-1.07.13c-.07,0-.13.06-.16.13-.02.07,0,.15.05.2l.79.73-.21,1.05c-.01.07.01.15.07.19.06.04.14.05.2.01l.94-.52.94.52c.06.04.14.03.2-.01.06-.04.09-.12.07-.19l-.21-1.05.79-.73c.05-.05.07-.13.05-.2-.02-.07-.08-.12-.16-.13l-1.07-.13-.45-.98Zm2.46,4.96s-.07-.08-.13-.08-.1.03-.13.08l-.34.73-.8.09c-.05,0-.1.04-.12.1-.02.05,0,.11.04.15l.59.55-.16.79c-.01.05.01.11.06.14.04.03.1.04.15,0l.7-.39.7.39s.11.02.15,0c.04-.03.07-.09.06-.14l-.16-.79.59-.55s.06-.09.04-.15c-.02-.05-.06-.09-.12-.1l-.8-.09-.34-.73Z"
        fill={color ? color : '#fff'}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Generate;
