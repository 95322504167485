import Bin from "assets/icons/Bin";
import Edit from "assets/icons/Edit";
import Eye from "assets/icons/Eye";
import Graph from "assets/icons/Graph";
import Share from "assets/icons/Share";
import styles from "assets/styles/modules/ProjectItemModal.module.scss";
import { useEffect, useRef, useState } from "react";
import useExperiments from "lib/hooks/useExperiments";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { modalAnimation } from "utilities/animations";

import ShareModal from "./ShareModal";
import DeleteConfirmationModal from "../Modal/DeleteConfirmationModal";

const ProjectItemModal = ({ setActive, experiment, userId }) => {
  const [shareModalisActive, setShareModalisActive] = useState(false);
  const [modalHeight, setModalHeight] = useState(0);
  const [deleteModalIsActive, setDeleteModalIsActive] = useState(false);
  const navigate = useNavigate();
  const experiments = useExperiments(userId);
  const container = useRef(null);

  const deleteOne = async () => {
    await experiments.deleteOne(experiment._id);
    window.location.reload();
  };

  const handleShareClick = () => {
    setShareModalisActive(!shareModalisActive);
  };

  useEffect(() => {
    setModalHeight(container.current.offsetHeight);
  }, [container.current]);

  return (
    <>
      <motion.div
        className={`${styles.wrapper}`}
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={modalAnimation}
      >
        <div
          className={`${styles.overlay} bg-blur`}
          onClick={() => setActive(false)}
        ></div>

        <div className={`${styles.container} shadow-md`} ref={container}>
          <ul className={styles.list}>
            <li onClick={() => navigate(`/spaces/${experiment._id}/edit`)}>
              Edit
              <span className={styles.icon}>
                <Edit />
              </span>
            </li>

            <li onClick={() => navigate(`/spaces/${experiment._id}`)}>
              Preview
              <span className={styles.icon}>
                <Eye />
              </span>
            </li>

            <li onClick={handleShareClick}>
              Share
              <span className={styles.icon}>
                <Share />
              </span>
            </li>

            {/* <li onClick={() => navigate(`/spaces/${experiment._id}`)}>
              <span className={styles.icon}>
                <Graph />
              </span>
              Analytics
            </li> */}

            <li onClick={() => setDeleteModalIsActive(true)}>
              Delete
              <span className={styles.icon}>
                <Bin />
              </span>
            </li>
          </ul>
        </div>
      </motion.div>

      <AnimatePresence>
        {deleteModalIsActive && (
          <DeleteConfirmationModal
            close={() => setDeleteModalIsActive(false)}
            confirm={deleteOne}
            height={modalHeight}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {shareModalisActive && (
          <ShareModal
            experiment={experiment}
            height={modalHeight}
            setActive={setShareModalisActive}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default ProjectItemModal;
