import { Form, Formik } from "formik";
import { AnimatePresence } from "framer-motion";
import {
  SpaceHomeTitleSchema,
  SpaceHomeImageSchema,
  SpaceTextSchema,
} from "schemas";
import useScrollBlock from "lib/hooks/useScrollBlock";
import FormEditImage from "../../Forms/FormEditImage";
import FormField from "../../Forms/FormField";
import FormTextarea from "components/common/Forms/FormTextarea";
import Modal from "../Modal";
import ModalBody from "../ModalBody";
import ModalHeading from "../ModalHeading";
import ModalLevel from "../ModalLevel";
import ModalOptions from "../ModalOptions";
import ModalOptionsLine from "../ModalOptionsLine";
import GenerateTextModal from "../GenerateTextModal";
import ImageEditModal from "../ImageEditModal";
import GenerateImageModal from "../GenerateImageModal";
import useSectionModal from "lib/hooks/useSectionModal";
import SaveButton from "../SaveButton";

const AboutModal = ({
  active,
  setActive,
  content,
  setTempImage,
  setTempTitle,
  setTempText,
  tempImage,
  tempText,
  tempTitle,
}) => {
  useScrollBlock(active);

  const sectionModal = useSectionModal(
    content,
    setActive,
    setTempImage,
    tempImage,
    tempText,
    tempTitle,
    "About"
  );

  return (
    <AnimatePresence>
      {active && (
        <>
          <ImageEditModal
            active={sectionModal.openImageEditModal}
            handleClose={sectionModal.handleOpenImageEditModal}
            img={tempImage || content.images[0]}
            rowId={content.rowId}
            setTempImage={setTempImage}
          />

          <GenerateImageModal
            active={sectionModal.openGenerateImageModal}
            handleClose={sectionModal.handleOpenGenerateImageModal}
            setTempImage={setTempImage}
          />

          <GenerateTextModal
            active={sectionModal.openGenerateTextModal}
            handleClose={sectionModal.handleOpenGenerateTextModal}
            setTempText={setTempText}
          />

          <Modal handleClose={sectionModal.handleHeadingClose}>
            <ModalHeading
              handleBack={sectionModal.handleBack}
              handleHeadingClose={sectionModal.handleHeadingClose}
              levelIsActive={sectionModal.activeLevel !== ""}
              title={sectionModal.headerTitle}
            />
            
            <ModalBody activeLevel={sectionModal.activeLevel}>
              <ModalOptions active={sectionModal.activeLevel === ""}>
                <ModalOptionsLine handleClick={() => sectionModal.activateSubLevel("About Title") } title={"Title"} />

                <ModalOptionsLine handleClick={() => sectionModal.activateSubLevel("About Image") } title={"Image"} />

                <ModalOptionsLine handleClick={() => sectionModal.activateSubLevel("About Text") } title={"Text"} />
              </ModalOptions>

              {/* Title Level */}

              <ModalLevel active={sectionModal.activeLevel === "About Title"} columns={1}>
                <div>
                  <Formik
                    enableReinitialize={true}
                    initialTouched={{ title: true }}
                    initialValues={{ title: tempTitle !== null ? tempTitle : content?.texts[0], }}
                    onSubmit={sectionModal.handleTitleEditGenerate}
                    validationSchema={SpaceHomeTitleSchema}
                  >
                    {({ errors, touched, handleChange, submitForm }) => (
                      <>
                        <Form>
                          <FormField
                            customChangeCallback={setTempTitle}
                            errors={errors}
                            handleChange={handleChange}
                            id="title"
                            labeled="false"
                            title=""
                            touched={touched}
                            type="text"
                          />
                        </Form>

                        <SaveButton handleSubmit={submitForm} />
                      </>
                    )}
                  </Formik>
                </div>
              </ModalLevel>

              {/* Image Level */}

              <ModalLevel active={sectionModal.activeLevel === "About Image"} columns={2}>
                <Formik
                  initialValues={{ file: null }}
                  onSubmit={sectionModal.handleImageEditGenerate}
                  validationSchema={SpaceHomeImageSchema}
                >
                  {({ errors, touched, setFieldValue, submitForm }) => (
                    <>
                      <Form>
                        <FormEditImage
                          errors={errors}
                          img={tempImage || content.images[0]}
                          openGenerateImageModal={sectionModal.handleOpenGenerateImageModal}
                          openImageEditModal={sectionModal.handleOpenImageEditModal}
                          rowId={content.rowId}
                          setFieldValue={setFieldValue}
                          setTempImage={setTempImage}
                          touched={touched}
                        />
                      </Form>

                      <SaveButton handleSubmit={sectionModal.handleImageEditGenerate} />
                    </>
                  )}
                </Formik>
              </ModalLevel>

              {/* Text Level */}

              <ModalLevel
                active={sectionModal.activeLevel === "About Text"}
                columns={1}
              >
                <Formik
                  enableReinitialize={true}
                  initialTouched={{ text: true }}
                  initialValues={{ text: tempText !== null ? tempText : content?.texts[1], }}
                  onSubmit={sectionModal.handleTextEditGenerate}
                  validationSchema={SpaceTextSchema}
                >
                  {({ errors, touched, handleChange, submitForm }) => (
                    <>
                      <Form>
                        <FormTextarea
                          customChangeCallback={setTempText}
                          errors={errors}
                          handleChange={handleChange}
                          id="text"
                          labeled={false}
                          openGenerateModal={sectionModal.setOpenGenerateTextModal}
                          touched={touched}
                          value={tempText || content.texts[1]}
                        />
                      </Form>

                      <SaveButton handleSubmit={() => { submitForm(); sectionModal.handleBack(); }} />
                    </>
                  )}
                </Formik>
              </ModalLevel>
            </ModalBody>
          </Modal>
        </>
      )}
    </AnimatePresence>
  );
};

export default AboutModal;
