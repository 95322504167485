import Plus from 'assets/icons/Plus';
import React, {useRef, useState} from 'react';
import {resizeFile} from 'utilities/resizeFile';
import UploadButton from '../Buttons/UploadButton';
import EditButton from '../Buttons/EditButton';
import styles from 'assets/styles/modules/FormEditImage.module.scss';
import cn from 'classnames';

import useExperimentSections from 'lib/hooks/useExperimentSections';
import Generate from 'assets/icons/Generate';

const FormImageAdd = ({
  errors,
  id,
  img,
  labeled,
  openGenerateImageModal,
  openImageEditModal,
  rowId,
  setFieldValue,
  setTempImage,
  slot,
  title,
  touched,
}) => {
  const file = useRef(null);
  const [thumb, setThumb] = useState(null);
  const sections = useExperimentSections();

  const handleImageUpload = async (e) => {
    /*
      This is correctly working if we are replacing an image, which, in most cases we are.
      However, if we are adding a new image, we need to handle that differently. This is the case for the ImagesSection / ImagesModal.
      In that section we have the ability to add multiple new images, and we need to handle that differently.
      1. We need to check if the image is new or a replacement
      2. If it's new, we won't have a originalImageId and we need to handle that case differently.
    */

    // if the user canceled upload, do nothing
    if (!e?.currentTarget?.files || !e.currentTarget.files[0]) return;
    const file = e.currentTarget.files[0];

    // get ID of previous image for replacement
    const urlSplitBySlash = img.split('/');
    const originalImageId = urlSplitBySlash[urlSplitBySlash.length - 1].split('.')[0];

    // base64 the image for upload
    const base64 = await resizeFile(file, 300, 500, 1000, 1000);

    // upload the image
    const response = await sections.replaceImage(
      rowId,
      originalImageId,
      base64,
      file.type,
    );

    setFieldValue('file', file);
    setThumb(response.outputUrl);

    // Slot will be present if this is a repeated image (like in the ImagesModal)
    if(slot) {
      setTempImage(response.outputUrl, slot);
    } else {
      setTempImage(response.outputUrl);
    }
  };

  const openImageDialog = () => {
    file.current.click();
  };

  return (
    <>
      <div className={styles.col}>
        <EditButton square action={openImageEditModal} />
        <UploadButton square action={openImageDialog} />
      </div>

      <div className="inputFile inputFile--image">
        <label className={`inputFile_label ${labeled ? '' : 'visually-hidden'}`} htmlFor={id}>
          {title}
        </label>

        <input
          accept="image/*"
          className="inputFile__input"
          id={id || 'file'}
          name={id || 'file'}
          onChange={handleImageUpload}
          ref={file}
          type="file"
        />

        {!(thumb || img) && (
          <div className="inputFile__icon w-16">
            <Plus color="#002534" />
          </div>
        )}

        {/* the error always displays because it's checking for file upload, not existing images */}
        {/* {errors[id] && touched[id] &&
        <div className='sp1 formRow_message'>{ errors[id] }</div>
      } */}

        <div className="inputFile__thumbnail" onClick={openImageDialog}>
          {(thumb || img) && (
            <img alt="image" className="inputFile__thumbnail__img" id={`inputFile__thumbnail__${thumb || img}`} src={thumb || img} />
          )}
        </div>
      </div>

      <div className="flex items-end">
        <button className={cn(['border-gradient-gray50', styles.generate])} onClick={slot ? () => openGenerateImageModal(slot) : openGenerateImageModal}>
          <span className="w-16">
            <Generate color="#002534" />
          </span>
        </button>
      </div>
    </>
  );
};

export default FormImageAdd;
