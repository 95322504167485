const elements = require('./elements');
const routes = require('./routes');
const text = require('./text');
const user = require('./user');
const websockets = require('./websockets');
const animations = require('./animations');

const utilities = {
  elements,
  routes,
  text,
  user,
  websockets,
  animations
}

export default utilities;
