import styles from "assets/styles/modules/DashboardSpacesCard.module.scss";
import SocialData from "../common/Project/SocialData";
import MakePublicConfirmationModal from "../common/Modal/Dashboard/MakePublicConfirmationModal";
import ProjectItemModal from "../common/Project/ProjectItemModal";
import { useEffect, useRef, useState } from "react";
import Ellipsis from "assets/icons/Ellipsis";
import useExperiments from "lib/hooks/useExperiments";
import { useStore } from "state/store";
import useAnimate from "lib/hooks/useAnimate";
import DashboardCard from "components/dashboard/DashboardCard";
import DashboardCardIframe from "components/dashboard/DashboardCardIframe";
import { AnimatePresence } from "framer-motion";

const DashboardSpacesCard = ({ experiment, rowIndex }) => {
  const actions = useRef(null);
  const publishedButton = useRef(null);
  const title = useRef(null);
  const [experimentData, setExperimentData] = useState(null);
  const [showMakePublicModal, setShowMakePublicModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const userData = useStore((state) => state.user);
  const experiments = useExperiments(userData.userId);
  const [published, setPublished] = useState(false);

  useAnimate(
    [actions.current, publishedButton.current, title.current],
    500,
    styles.visible
  );

  useEffect(() => {
    experiments.fetchExperiment(experiment._id).then((data) => {
      setPublished(data?.published ? data.published : false);
      setExperimentData(data);
    });
  }, [experiment]);

  const updatePublishedStatus = async (newPublishedStatus) => {
    await experiments
      .updateExperiment({ _id: experiment._id, published: newPublishedStatus })
      .then((data) => {
        setPublished(newPublishedStatus);
        setShowMakePublicModal(false);
      });
  };

  const onPublishChange = async () => {
    if (published) {
      updatePublishedStatus(false);
    } else {
      setShowMakePublicModal(true);
    }
  };

  const publishSpace = async () => {
    updatePublishedStatus(true);
  };

  return (
    <>
      <DashboardCard>
        <div className={styles.header} ref={title}>
          <div
            className={`${styles.heading} flex items-center justify-between`}
          >
            <h2 className={`sp1 font-semibold flex-1 ${styles.title}`}>
              {experimentData?.title ? experimentData.title : "Untitled"}
            </h2>

            <div
              className={`${styles.actions}`}
              onClick={() => setShowModal(true)}
              ref={actions}
            >
              <Ellipsis />
            </div>
          </div>

          <SocialData
            likes={experimentData?.likedBy?.length || 0}
            shareData={{ text: experimentData?.description, title: experimentData?.title, url: `/spaces/${experiment?._id}`, }}
            views={experimentData?.views || 0}
          />
        </div>

        <div className={styles.outliner} key={experiment.previewId}>
          <DashboardCardIframe experiment={experiment} rowIndex={rowIndex} />

          <div className={`${styles.status} ${published ? styles.pubishedSpace : 'unpublished'}`} ref={publishedButton}>
            <div className={`${styles.cta} ${published ? styles.pubishedCta : styles.publish} sp2 font-semibold`} onClick={onPublishChange}>
              {published ? <>Public</> : <>Private</>}
            </div>
          </div>          
        </div>
      </DashboardCard>

      <AnimatePresence>
        {showModal && (
          <ProjectItemModal
            setActive={setShowModal}
            experiment={experimentData}
            userId={userData.userId}
          />
        )}
      </AnimatePresence>

      <MakePublicConfirmationModal
        active={showMakePublicModal}
        confirm={publishSpace}
        setActive={setShowMakePublicModal}
      />
    </>
  );
};

export default DashboardSpacesCard;
