import {motion} from 'framer-motion';
import {useState} from 'react';

import useSelectTemplate from 'lib/hooks/useSelectTemplate';
import useSwipeHandlers from 'lib/hooks/useSwipeHandlers';
import {spring} from 'utilities/animations';

import Bin from 'assets/icons/Bin';
import CircleIcon from 'assets/icons/CircleIcon';
import globalSectionStyles from 'assets/styles/modules/Section.module.scss';
import Plus from 'assets/icons/Plus';
import styles from 'assets/styles/modules/ImagesSection.module.scss';

import EditButton from './ui/EditButton';
import ImagesModal from 'components/common/Modal/Images/ImagesModal';

const ImagesSection = ({content, isShowPage, openEdit, removeSection}) => {
  const [active, setActive] = useState(false);
  const [tempImages, setTempImages] = useState(content?.images || []);
  const {swipeHandlers, layout} = useSwipeHandlers(content?.variationNumber || 1);
  useSelectTemplate(content, layout === 0 ? 1 : layout);

  return (
    <>
      <section
        {...(isShowPage ? [] : swipeHandlers)}
        className={globalSectionStyles.container}
        draggable="false"
        style={{userSelect: 'none'}}
      >
        <ImagesModal
          active={active}
          content={content}
          setActive={setActive}
          setTempImages={setTempImages}
          tempImages={tempImages}
        />

        {openEdit && (
          <EditButton openModal={setActive} position="bottomRightNoImage" />
        )}

        <div
          className={`${
            isShowPage
              ? globalSectionStyles.contentFullPageView
              : globalSectionStyles.contentEditView
          } ${styles.content}`}
          data-count={content.images.length}
          data-layout={layout === 0 ? 1 : layout}
        >
          {content?.images?.map((image, i) => (
            <motion.div
              className={styles.image}
              key={`${image}__${i}`}
              layout
              transition={spring}
            >
              <img
                src={tempImages[i] || image}
                alt="Off Space"
                draggable="false"
              />
            </motion.div>
          ))}
        </div>

        {removeSection && (
          <div className={globalSectionStyles.delete} onClick={() => removeSection(content.rowId)}>
            <CircleIcon variant={'filledInverted'} iconWidth={'w-14'}>
              <Bin />
            </CircleIcon>
          </div>
        )}

        {openEdit && (
          <div className={globalSectionStyles.addSection} onClick={() => openEdit({status: true, afterPosition: content.position})}>
            <CircleIcon iconWidth={'w-12'}>
              <Plus />
            </CircleIcon>
          </div>
        )}
      </section>

      {openEdit && <div className={globalSectionStyles.divider}></div>}
    </>
  );
};

export default ImagesSection;
