import React from 'react';
import styles from 'assets/styles/modules/ModalHeading.module.scss';
import Close from 'assets/icons/Close';
import Check from 'assets/icons/Check';
import Chevron from 'assets/icons/Chevron';

const ModalHeading = ({
  title,
  handleBack,
  handleHeadingClose,
  levelIsActive,
}) => {
  return (
    <div
      className={`${styles.heading} ${
        levelIsActive ? styles.levelIsActive : ''
      }`}
    >
      {levelIsActive ? (
        <div className={`${styles.back} sp1`} onClick={handleBack}>
          <span className={`${styles.icon} ${styles.chevron}`}>
            <Chevron />
          </span>
          Back
        </div>
        )
        :
        <span></span>
      }
      <div className='font-semibold text-center'>{title}</div>
      <div className={styles.action}>
        {!levelIsActive && (
          <span
            className={`${styles.icon} ${styles.close}`}
            onClick={handleHeadingClose}
          >
            <Close color={'#002534'} />
          </span>
        )}
      </div>
    </div>
  );
};

export default ModalHeading;
