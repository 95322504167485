import React, { useState } from "react";
import { Field } from "formik";
import Generate from "assets/icons/Generate";

const FormTextarea = ({
  id,
  title,
  errors,
  touched,
  labeled,
  showCounter,
  initialText,
  handleChange,
  customChangeCallback,
  value,
  openGenerateModal
}) => {
  const maxCount = 306;
  const [count, setCount] = useState(value ? value.length : 0);

  return (
    <div className="formRow formRow--ai">
      <label
        htmlFor={id}
        className={`formRow_label ${labeled ? "" : "visually-hidden"}`}
      >
        {title}
      </label>
      <div className="formRow_cols">
        <Field
          id={id}
          name={id}
          placeholder={title}
          component="textarea"
          rows="10"
          onChange={(e) => {
            if (e.target.value.length > maxCount) return;
            const characterCount = e.target.value.length;
            setCount(characterCount);
            handleChange && handleChange(e);
            customChangeCallback &&
              typeof customChangeCallback === "function" &&
              customChangeCallback(e.target.value);
          }}
        />
        <button className="border-gradient-gray50 formRow_generate" onClick={() => openGenerateModal(true)}>
          <span>
            <Generate color="#002534" />
          </span>
        </button>
      </div>
      <div className="formRow_cols">
        <div className="sp1 formRow_message">
          {count}/{maxCount}
        </div>
      </div>
      <div className="formRow_cols">
        {errors[id] && touched[id] ? (
          <div className="sp1 formRow_message">{errors[id]}</div>
        ) : null}
      </div>
    </div>
  );
};

export default FormTextarea;
