const Upload = () => {
  return (
    <svg viewBox="0 0 10 13.4">
      <path
        d="m5.75,9.14V2.46l2.43,2.43c.29.29.77.29,1.06,0,.29-.29.29-.77,0-1.06L5.71.29c-.39-.39-1.02-.39-1.41,0L.76,3.83c-.29.29-.29.77,0,1.06.29.29.77.29,1.06,0l2.43-2.43v6.68c0,.41.34.75.75.75s.75-.34.75-.75Zm3.5,4.26c.41,0,.75-.34.75-.75s-.34-.75-.75-.75H.75c-.41,0-.75.34-.75.75s.34.75.75.75h8.5Z"
        fill="#2f2f2f"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Upload;
