export const createExperimentPageSlides = [
    {
        id: 1,
        title: "Step 01 - Title",
        description: "Start by giving a title to your new space.  This title can always be modified later. For example the title could be your name, the name of your project, the name of your business, etc.",
        image: "/generate-space/01.png",
    },
    {
        id: 2,
        title: "Step 02 - Cover",
        description: "You can add a cover image or short video to your space. This can be modified later. If you don’t have one, no worries, I’ll generate one for you.",
        image: "/generate-space/02.png",
    },
    {
        id: 3,
        title: "Step 03 - Short description",
        description: "You can add a short description of your space. This could be as small as a sentence or you can go to town. This will help me to craft tailored content for your space.",
        image: "/generate-space/03.png",
    },
    {
        id: 4,
        title: "Step 04 - Generate",
        description: "You are now ready to generate your Space.",
        image: "/generate-space/04.png",
    },
];

export const editExperimentPageSlides = [
    {
        id: 1,
        title: "Section Composition",
        description: "You can change the composition of any section by swiping left or right.",
        image: "/edit-space/01.png",
    },
    {
        id: 2,
        title: "Add or remove a section",
        description: "You can add a section by clicking the + button and select a template from the list. You can delete a section by clicking on the trash icon.",
        image: "/edit-space/02.png",
    },
    {
        id: 3,
        title: "Edit your content inside a section",
        description: "You can edit any content inside a section by clicking the edit icon",
        image: "/edit-space/03.png",
    },
];

export const generateYourContentSlides = [
    {
        id: 1,
        title: "Type your prompt",
        description: "Type in your prompt and hit send. I will craft custom content based on your input.",
        image: "/generate-content/01.png",
    },
    {
        id: 2,
        title: "Select",
        description: "I will generate 4 options to choose from, just select your favourite by clicking on it. ",
        image: "/generate-content/02.png",
    },
    {
        id: 3,
        title: "Generate more",
        description: "Couldn’t find the perfect match. You can always generate more options to choose from. Options to generate varies according to your plan. click here for more details  ",
        image: "/generate-content/03.png",
    },
    {
        id: 4,
        title: "Finish",
        description: "When you’ve selected the perfect match, hit done to import the generated content inside your section",
        image: "/generate-content/04.png",
    },
];

export const dashboardSlides = [
    {
        id: 1,
        title: "Your Spaces",
        description: "Every time you create a space it will be displayed on your dashboard.",
        image: "/dashboard/01.png",
    },
    {
        id: 2,
        title: "Action Menu",
        description: "You can open a sub-menu for each space by clicking this icon.",
        image: "/dashboard/02.png",
    },
    {
        id: 3,
        title: "Publish your Space",
        description: "By default your space is private, but when you are ready, you can publish your space, and share it on the OffSpace Feed. You can always edit the content later. ",
        image: "/dashboard/03.png",
    },
    {
        id: 4,
        title: "Discover",
        description: "Discover spaces created by other users on the Feed. ",
        image: "/dashboard/04.png",
    },
];