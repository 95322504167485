import React, { useEffect, useRef, useState } from "react";
import styles from "assets/styles/modules/Create.module.scss";
import Help from "assets/icons/Help";
import cn from "classnames";
import Edit from "assets/icons/Edit";
import Bin from "assets/icons/Bin";
import Plus from "assets/icons/Plus";
import Generate from "assets/icons/Generate";
import { resizeFile } from "utilities/resizeFile";

import LoadingOrb from "components/createExperiment/LoadingOrb";

import { useStore } from "state/store";
import { sendAsWsRequest } from "utilities/websockets";
import useRequireAuthenticated from "lib/hooks/useRequireAuthenticated";
import { useNavigate } from "react-router-dom";
import InAppGuidanceSlider from "components/common/InAppGuidance/InAppGuidance";
import { createExperimentPageSlides } from "lib/data/in-app-guidance.js";
import useExperiments from "lib/hooks/useExperiments";
import { convertVideoToGif } from "utilities/convertVideoToGif";
import { spinner } from "utilities/spinner";

const TextField = ({ setText, placeholder, textarea }) => {
  return (
    <div className={cn(styles.field, styles.text)}>
      {textarea ? (
        <textarea onChange={(e) => setText(e.target.value)} placeholder={placeholder} rows="6" />
      ) : (
        <input className="font-semibold" onChange={(e) => setText(e.target.value)} placeholder={placeholder} type="text" />
      )}

      <div className={styles.field__col}>
        <div className={styles.field__icon}>
          <span className="flex w-14">
            <Edit />
          </span>
        </div>
      </div>
    </div>
  );
};

const AddImage = ({ thumb, setThumb }) => {
  const file = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleImageUpload = async (e) => {
    const file = e.currentTarget.files[0];
    let base64;

    if (file.type === "video/mp4") {
      setIsLoading(true);
      setThumb(spinner);
      base64 = await convertVideoToGif(file);
      base64 = "data:image/gif;base64," + base64;
    } else {
      base64 = await resizeFile(file);
    }

    setIsLoading(false);
    setThumb(base64);
  };

  const openImageDialog = () => {
    file.current.click();
  };

  return (
    <div className={cn(styles.field, styles.image)}>
      <input
        accept="image/*,video/mp4"
        id="file"
        name="file"
        onChange={handleImageUpload}
        ref={file}
        style={{ display: "none" }}
        type="file"
      />

      <button
        className={cn([
          styles.addImage,
          styles.hasImage,
          {
            [styles.isLoading]: isLoading,
          },
        ])}
        onClick={openImageDialog}
      >
        <span className="flex w-14">
          <Plus color="#2f2f2f" />
        </span>
        {thumb && <img src={thumb} alt="Space Image" />}
      </button>

      <div className={styles.field__col}>
        <button className={cn("transition-all", styles.field__icon)} onClick={() => setThumb(null)}>
          <span className="flex w-14">
            <Bin />
          </span>
        </button>
      </div>
    </div>
  );
};

const Create = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [thumb, setThumb] = useState(null);
  const [description, setDescription] = useState("");
  const userData = useStore((state) => state.user);
  const [showInAppGuidance, setShowInAppGuidance] = useState(() => !localStorage.getItem('in-app-guidance-viewed-create'));
  const userExperiments = useExperiments(userData.userId);
  const [creating, setCreating] = useState(false);
  const [generationId, setGenerationId] = useState('');
  const [spaceId, setSpaceId] = useState('');

  useRequireAuthenticated();

  const handleInAppGuidanceShow = (val) => {
    localStorage.setItem('in-app-guidance-viewed-create', 'true');
    setShowInAppGuidance(val);
  }

  const handleGenerate = async () => {
    if (!title || !description) return;

    setCreating(true);

    const gId = Math.random().toString(36).substring(2) + Date.now().toString(36);
    setGenerationId(gId);

    let thumbLocation = null;

    if (thumb) {
      const imageType = thumb.substring("data:image/".length, thumb.indexOf(";base64"));
      const res = await userExperiments.uploadImage(userData.userId, `${userData.userId}-space-thumb.${imageType}`, thumb);
      thumbLocation = res.location;
    }

    const body = {
      selectedDescription: description,
      selectedTitle: title,
      uploadedImageUrl: thumbLocation,
      userId: userData.userId,
    };

    const creationResponse = await sendAsWsRequest("v1/experiments/create", body);

    if (creationResponse._id) {
      setSpaceId(creationResponse._id);
      setTimeout(() => localStorage.setItem(gId, creationResponse._id), 100);
    } else {
      setCreating(false);
    }
  };

  const handleNavigation = function() {
    navigate(`/spaces/${localStorage.getItem(generationId)}/edit`);
  };

  return (
    <>
      <section className="main scroll">
        <div className={styles.container}>
          {/* <h1 className={cn("st1", styles.heading)}>
            New Space{" "}
            <button onClick={() => setShowInAppGuidance(true)}>
              <Help />
            </button>
          </h1> */}

          <div className={styles.content} id="creation-scrollable">
            <div className={styles.main}>
              <TextField setText={setTitle} placeholder="Type your title here" />

              <AddImage thumb={thumb} setThumb={setThumb} />

              <TextField placeholder="Type a short description here" setText={setDescription} textarea />
            </div>

            <div className={styles.loading}>
              <LoadingOrb generationId={generationId} triggerRedirect={handleNavigation} />
            </div>
          </div>

          <button className={cn("border-gradient-gray50 hover-gradient transition-all", styles.cta)} onClick={handleGenerate}>
            <span className={cn([styles.cta__text, {[styles.cta__text__loading]: creating,},])}>
              {creating ? "Generating..." : "Generate"}
            </span>

            <span className={styles.cta__icon}>
              <Generate color="#2f2f2f" />
            </span>
          </button>
        </div>
      </section>

      <InAppGuidanceSlider
        active={showInAppGuidance}
        heading="How to generate a new space"
        setActive={handleInAppGuidanceShow}
        slides={createExperimentPageSlides}
      />
    </>
  );
};

export default Create;
