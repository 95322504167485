import { Form, Formik } from "formik";
import { AnimatePresence } from "framer-motion";
import { SpaceHomeTitleSchema, SpaceHomeImageSchema } from "schemas";
import useScrollBlock from "lib/hooks/useScrollBlock";

import FormEditImage from "../../Forms/FormEditImage";
import FormField from "../../Forms/FormField";
import Modal from "../Modal";
import ModalBody from "../ModalBody";
import ModalHeading from "../ModalHeading";
import ModalLevel from "../ModalLevel";
import ModalOptions from "../ModalOptions";
import ModalOptionsLine from "../ModalOptionsLine";
import ImageEditModal from "../ImageEditModal";
import GenerateImageModal from "../GenerateImageModal";
import useSectionModal from "lib/hooks/useSectionModal";
import SaveButton from "../SaveButton";

const HomeModal = ({
  active,
  setActive,
  content,
  setTempImage,
  setTempTitle,
  tempTitle,
  tempImage,
}) => {
  useScrollBlock(active);

  const sectionModal = useSectionModal(
    content,
    setActive,
    setTempImage,
    tempImage,
    null,
    tempTitle,
    "Home"
  );

  return (
    <AnimatePresence>
      {active && (
        <>
          <ImageEditModal
            active={sectionModal.openImageEditModal}
            handleClose={sectionModal.handleOpenImageEditModal}
            img={tempImage || content.images[0]}
            rowId={content.rowId}
            setTempImage={setTempImage}
          />

          <GenerateImageModal
            setTempImage={setTempImage}
            active={sectionModal.openGenerateImageModal}
            handleClose={sectionModal.handleOpenGenerateImageModal}
          />

          <Modal handleClose={sectionModal.handleHeadingClose}>
            <ModalHeading
              title={sectionModal.headerTitle}
              handleHeadingClose={sectionModal.handleHeadingClose}
              handleHeadingSubmit={sectionModal.handleHeadingSubmit}
              handleBack={sectionModal.handleBack}
              levelIsActive={sectionModal.activeLevel !== ""}
            />
            <ModalBody activeLevel={sectionModal.activeLevel}>
              <ModalOptions active={sectionModal.activeLevel === ""}>
                <ModalOptionsLine
                  handleClick={() =>
                    sectionModal.activateSubLevel("Home Title")
                  }
                  title={"Title"}
                />

                <ModalOptionsLine
                  handleClick={() =>
                    sectionModal.activateSubLevel("Home Image")
                  }
                  title={"Image"}
                />
              </ModalOptions>

              {/* Title Level */}

              <ModalLevel
                active={sectionModal.activeLevel === "Home Title"}
                columns={1}
              >
                <div>
                  <Formik
                    enableReinitialize={true}
                    initialTouched={{ title: true }}
                    initialValues={{
                      title: tempTitle !== null ? tempTitle : content?.texts[0],
                    }}
                    validationSchema={SpaceHomeTitleSchema}
                    onSubmit={sectionModal.handleTitleEditGenerate}
                  >
                    {({ errors, touched, handleChange, submitForm }) => (
                      <>
                        <Form>
                          <FormField
                            customChangeCallback={setTempTitle}
                            errors={errors}
                            handleChange={handleChange}
                            id="title"
                            labeled="false"
                            title=""
                            touched={touched}
                            type="text"
                          />
                        </Form>
                        
                        <SaveButton handleSubmit={() => { submitForm(); sectionModal.handleBack(); }} />
                      </>
                    )}
                  </Formik>
                </div>
              </ModalLevel>

              {/* Image Level */}

              <ModalLevel active={sectionModal.activeLevel === "Home Image"} columns={2}>
                <Formik initialValues={{ file: null }} onSubmit={sectionModal.handleImageEditGenerate} validationSchema={SpaceHomeImageSchema}>
                  {({ errors, touched, setFieldValue }) => (
                    <>
                      <Form>
                        <FormEditImage
                          errors={errors}
                          generate={sectionModal.handleRegenerateImage}
                          img={tempImage || content.images[0]}
                          openGenerateImageModal={sectionModal.handleOpenGenerateImageModal}
                          openImageEditModal={sectionModal.handleOpenImageEditModal}
                          rowId={content.rowId}
                          setFieldValue={setFieldValue}
                          setTempImage={setTempImage}
                          touched={touched}
                        />
                      </Form>

                      <SaveButton handleSubmit={sectionModal.handleImageEditGenerate} />
                    </>
                  )}
                </Formik>
              </ModalLevel>
            </ModalBody>
          </Modal>
        </>
      )}
    </AnimatePresence>
  );
};

export default HomeModal;
