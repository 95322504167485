export const spring = {
  type: 'spring',
  stiffness: 700,
  damping: 100,
  layout: {
    duration: 0.3,
  },
};

export const modalAnimation = {
  hidden: {
    opacity: 0,
    y: '40px',
    transition: {
      duration: 0.5,
      ease: [0.2, 0.65, 0.3, 0.9],
    },
  },
  visible: {
    opacity: 1,
    y: '0',
    transition: {
      duration: 0.5,
      ease: [0.2, 0.65, 0.3, 0.9],
    },
  },
};

export const revealOpacity = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: [0.2, 0.65, 0.3, 0.9],
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: [0.2, 0.65, 0.3, 0.9],
    },
  },
};

export const revealHelp = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: [0.2, 0.65, 0.3, 0.9],
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 2,
      ease: [0.2, 0.65, 0.3, 0.9],
    },
  },
};
