import { useEffect, useRef, useState } from "react";
import ModalHeading from "../ModalHeading";
import ModalOptions from "../ModalOptions";
import ModalOptionsLine from "../ModalOptionsLine";
import FormField from "../../Forms/FormField";
import FormFieldRepeater from "../../Forms/FormFieldRepeater";
import { Form, Formik } from "formik";
import { contactLinksSchema, SpaceHomeTitleSchema, SpaceTextSchema, } from "schemas";
import useExperimentSections from "lib/hooks/useExperimentSections";
import Modal from "../Modal";
import ModalLevel from "../ModalLevel";
import FormTextarea from "components/common/Forms/FormTextarea";
import GenerateButton from "components/common/Buttons/GenerateButton";
import { AnimatePresence } from "framer-motion";
import { useStore } from "state/store";
import useScrollBlock from "lib/hooks/useScrollBlock";
import ModalBody from "../ModalBody";
import cn from "classnames";

const ContactModal = ({
  active,
  setActive,
  content,
  setTempLinkUrls,
  setTempLinkTitles,
  setTempTitle,
  setTempText,
  tempLinkTitles,
  tempLinkUrls,
}) => {
  const [headerTitle, setHeaderTitle] = useState(null);
  const [activeLevel, setActiveLevel] = useState("");
  const [linkCount, setLinkCount] = useState(parseInt(tempLinkUrls.length) + 1);
  const sections = useExperimentSections();
  const userData = useStore((state) => state.user);

  useScrollBlock(active);

  const activateSubLevel = (levelName) => {
    setActiveLevel(levelName);
  };

  const handleBack = () => {
    setActiveLevel("");
  };

  const handleHeadingClose = () => {
    // Just close the modal
    setActive(false);
  };

  useEffect(() => {
    if (activeLevel === "") {
      setHeaderTitle("Contact");
    } else {
      setHeaderTitle(activeLevel);
    }
  }, [activeLevel]);

  return (
    <AnimatePresence>
      {active && (
        <Modal handleClose={handleHeadingClose}>
          <ModalHeading
            title={headerTitle}
            levelIsActive={activeLevel !== ""}
            handleHeadingClose={handleHeadingClose}
            handleBack={handleBack}
          />

          <ModalBody activeLevel={activeLevel}>
            <ModalOptions active={activeLevel === ""}>
              <ModalOptionsLine
                title={"Title"}
                handleClick={() => activateSubLevel("Contact Title")}
              />
              <ModalOptionsLine
                title={"Links"}
                handleClick={() => activateSubLevel("Contact Links")}
              />
              <ModalOptionsLine
                title={"Text"}
                handleClick={() => activateSubLevel("Contact Text")}
              />
            </ModalOptions>

            {/* Title Level */}
            <ModalLevel active={activeLevel === "Contact Title"} columns={1}>
              <div>
                <Formik
                  initialValues={{ title: content?.texts[0] || "Keep in Touch", }}
                  validationSchema={SpaceHomeTitleSchema}
                  onSubmit={async (values) => {
                    const updatedTexts = content.texts;
                    updatedTexts[0] = values.title;
                    const response = await sections.updateTexts(
                      userData.userId,
                      content.rowId,
                      updatedTexts
                    );
                    if (response?.success) setActive(false);
                  }}
                >
                  {({ errors, touched, handleChange, submitForm }) => (
                    <>
                      <Form>
                        <FormField
                          id="title"
                          title=""
                          type="text"
                          errors={errors}
                          touched={touched}
                          labeled="false"
                          handleChange={handleChange}
                          customChangeCallback={setTempTitle}
                        />
                      </Form>

                      <div className="btn-container">
                        <button className="btn btn-primary" onClick={submitForm}>
                          Save
                        </button>
                      </div>
                    </>
                  )}
                </Formik>
              </div>
            </ModalLevel>

            {/* Text Level */}
            <ModalLevel active={activeLevel === "Contact Text"} columns={1}>
              <Formik
                initialValues={{ text: content?.texts[1] || "", }}
                validationSchema={SpaceTextSchema}
                onSubmit={async (values) => {
                  const updatedTexts = content.texts;
                  updatedTexts[1] = values.text;
                  const response = await sections.updateTexts(userData.userId, content.rowId, updatedTexts);
                  if (response?.success) setActive(false);
                }}
              >
                {({ errors, touched, handleChange, submitForm }) => (
                  <>
                    <Form>
                      <FormTextarea
                        id="text"
                        errors={errors}
                        touched={touched}
                        labeled={false}
                        handleChange={handleChange}
                        customChangeCallback={setTempText}
                      />
                    </Form>

                    <div className="btn-container">
                      <button className="btn btn-primary" onClick={submitForm}>
                        Save
                      </button>
                    </div>
                  </>
                )}
              </Formik>
            </ModalLevel>

            {/* Links Level */}
            <ModalLevel active={activeLevel === "Contact Links"} columns={1}>
              <Formik
                initialValues={{ links: content?.texts[3] || tempLinkUrls, titles: content?.texts[2] || tempLinkTitles, }}
                validationSchema={contactLinksSchema}
                onSubmit={async (values) => {
                  const linkTexts = values.links.map((link, i) => `name=${values.titles[i]}~url=${link}`);
                  const updatedTexts = content.texts.slice(0,1).concat(linkTexts.filter((str) => !!str));

                  const response = await sections.updateTexts(userData.userId, content.rowId, updatedTexts);
                  if (response?.success) setActive(false);
                }}
              >
                {({ errors, touched, handleChange, submitForm }) => (
                  <>
                    <Form>
                      {Array(
                        Math.max(linkCount, tempLinkTitles.length, content.texts.filter((val) => val.includes('url=')).length)
                      ).fill().map((unused, i) => {
                        const link = tempLinkUrls[i] || content.texts.filter((val) => val.includes('url='))[i]?.split('url=')[1];
                        const title = tempLinkTitles[i] || content.texts.filter((val) => val.includes('url='))[i]?.replace('name=', '').split('~')[0];
                      
                        return (
                          <div key={`link__${i}`} className={cn({ "mb-32": i !== linkCount - 1, })}>
                            <FormFieldRepeater
                              customChangeCallback={setTempLinkTitles}
                              errors={errors}
                              fieldIndex={i}
                              handleChange={handleChange}
                              id={`titles[${i}]`}
                              labeled="false"
                              title={`Title ${i + 1}`}
                              touched={touched}
                              type="text"
                              value={title}
                            />

                            <FormFieldRepeater
                              customChangeCallback={setTempLinkUrls}
                              errors={errors}
                              fieldIndex={i}
                              handleChange={handleChange}
                              id={`links[${i}]`}
                              labeled="false"
                              title={`URL ${i + 1}`}
                              touched={touched}
                              type="text"
                              value={link}
                            />
                          </div>
                        )
                      })}
                    </Form>

                    <div className="btn-container">
                      <button className="btn" onClick={() => setLinkCount(linkCount + 1)}>
                        Add Link
                      </button>
                    </div>

                    <div className="btn-container">
                      <button className="btn btn-primary" onClick={() => { submitForm(); handleBack(); }}>
                        Save
                      </button>
                    </div>
                  </>
                )}
              </Formik>
            </ModalLevel>
          </ModalBody>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default ContactModal;
