import styles from "assets/styles/modules/Dots.module.scss";
import cn from "classnames";

const Dots = ({ currentSlide, slides, showPlaceholder }) => {
  return (
    <div className={styles.dots}>
      {showPlaceholder && slides.length === 0
        ? [...Array(4).keys()].map((_, index) => (
            <span
              key={index}
              className={cn(
                "transition-all",
                styles.dot
              )}
            />
          ))
        : slides?.map((_, index) => (
            <span
              key={index}
              className={cn(
                "transition-all",
                styles.dot,
                index === currentSlide ? styles.active : ""
              )}
            />
          ))}
    </div>
  );
};

export default Dots;
