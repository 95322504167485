import globalSectionStyles from 'assets/styles/modules/Section.module.scss';
import styles from 'assets/styles/modules/VideoSection.module.scss';
import { useEffect, useState } from 'react';
import { spring } from 'utilities/animations';
import { motion } from 'framer-motion';
import EditButton from './ui/EditButton';
import Bin from 'assets/icons/Bin';
import CircleIcon from 'assets/icons/CircleIcon';
import Plus from 'assets/icons/Plus';
import image from 'assets/images/mariah-hewines-unsplash.png';
import useSelectTemplate from 'lib/hooks/useSelectTemplate';
import useSwipeHandlers from 'lib/hooks/useSwipeHandlers';
import VideoModal from 'components/common/Modal/Video/VideoModal';

const VideoSection = ({ content, isShowPage, openEdit, removeSection }) => {
  const [active, setActive] = useState(false);
  const [tempImage, setTempImage] = useState(null);
  const [tempTitle, setTempTitle] = useState(null);
  const [tempText, setTempText] = useState(null);
  const { swipeHandlers, layout } = useSwipeHandlers(content.variationNumber);
  useSelectTemplate(content, layout === 0 ? 1 : layout);

  return (
    <>
      <section { ...(isShowPage ? [] : swipeHandlers) } className={globalSectionStyles.container} draggable='false' style={{ userSelect: 'none' }}>
        <VideoModal
          active={active}
          setActive={setActive}
          content={content}
          setTempImage={setTempImage}
          setTempTitle={setTempTitle}
          setTempText={setTempText}
        />

        <EditButton openModal={setActive} position='bottomRightNoImage' />

        <div className={`${isShowPage ? globalSectionStyles.contentFullPageView : globalSectionStyles.contentEditView} ${styles.content}`} data-layout={layout === 0 ? 1 : layout}>
          <motion.div className={styles.image} layout transition={spring}>
            <img
              src={tempImage ? tempImage : image}
              alt={content.texts[0]}
              draggable='false'
            />
          </motion.div>
          
          <motion.h2 className={styles.heading} layout transition={spring}>
            {tempTitle ? tempTitle : content.texts[0]}
          </motion.h2>

          <motion.div className={styles.text} layout transition={spring}>
            {tempText ? tempText : content.texts[1]}
          </motion.div>

          <div className={styles.triangle}></div>
        </div>

        <div className={globalSectionStyles.delete} onClick={() => removeSection(content.rowId)}>
          <CircleIcon variant={'filledInverted'} iconWidth={'w-14'}>
            <Bin />
          </CircleIcon>
        </div>

        <div className={globalSectionStyles.addSection} onClick={() => openEdit({ status: true, afterPosition: content.position }) }>
          <CircleIcon iconWidth={'w-12'}>
            <Plus />
          </CircleIcon>
        </div>
      </section>

      <div className={globalSectionStyles.divider}></div>
    </>
  );
};

export default VideoSection;
