import React, { useState } from "react";
import { Field, useField } from "formik";
import cn from "classnames";
import styles from "assets/styles/modules/FormFieldInfo.module.scss";

const FormCta = ({
  customChangeCallback,
  errors,
  handleChange,
  idText,
  idUrl,
  labeled,
  placeholderText,
  placeholderUrl,
  title,
  touched,
}) => {
  const [field] = useField(idText);
  const [buttonText, setButtonText] = useState(field.value || "Contact me");

  const onChangeHandler = (e) => {
    if (!handleChange) return;

    if (e.target.id === idText) setButtonText(e.target.value);

    if (e.target.value === "") setButtonText("Contact me");

    handleChange(e);
    customChangeCallback !== undefined &&
      typeof customChangeCallback === "function" &&
      customChangeCallback(e.target.value);
  };

  return (
    <div className="formRow">
      <label
        className={`formRow_label ${labeled ? "" : "visually-hidden"}`}
        htmlFor={placeholderText}
      >
        {title}
      </label>

      <div
        className={cn([
          styles.buttonContainer,
          {
            [styles.disabled]: buttonText == "Contact me",
          },
        ])}
      >
        <div className={styles.button}>{buttonText}</div>
      </div>

      <Field
        id={idText}
        name={idText}
        onChange={onChangeHandler}
        placeholder={placeholderText ? placeholderText : ""}
        type="text"
      />

      <Field
        id={idUrl}
        name={idUrl}
        onChange={onChangeHandler}
        placeholder={placeholderUrl ? placeholderUrl : ""}
        type="text"
      />

      {(errors[idText] && touched[idText]) ||
        (errors[idUrl] && touched[idUrl] && (
          <div className="sp1 formRow_message">
            {errors[idUrl] || errors[idText]}
          </div>
        ))}
    </div>
  );
};

export default FormCta;
