import React, { useState } from "react";
import styles from "assets/styles/modules/FormFieldInfo.module.scss";
import Info from "assets/icons/Info";
import cn from "classnames";

const FormTextareaInfo = ({ title, text, icon, iconAction, maxCount }) => {
  const [count, setCount] = useState(text.length);

  return (
    <div
      className={cn([
        styles.card,
        icon ? styles.hasIcon : "",
        count > 46 ? styles.isMultipleLines : "",
      ])}
    >
      <div>
        <div className="sp1">{title}</div>
        <div className="font-semibold">{text}</div>
        {count > 0 && (
          <div
            className={cn(["sp1", styles.counter])}
          >{`${count} / ${maxCount}`}</div>
        )}
      </div>
      {icon && (
        <div className={styles.icon}>
          <Info />
        </div>
      )}
    </div>
  );
};

export default FormTextareaInfo;
