import { useStore } from 'state/store';
import Bookmark from 'assets/icons/Bookmark';
import CircleIcon from 'assets/icons/CircleIcon';
import ExternalLink from 'assets/icons/ExternalLink';
import Heart from 'assets/icons/Heart';
import styles from 'assets/styles/modules/ProjectItemActions.module.scss';
import useExperiments from 'lib/hooks/useExperiments';
import { useEffect, useRef, useState } from 'react';
import ArrowRightAngle from 'assets/icons/ArrowRightAngle';
import { useNavigate } from 'react-router-dom';
import useAnimate from 'lib/hooks/useAnimate';

const ProjectItemActions = ({ experimentData, variant, experimentId }) => {
  const navigate = useNavigate();
  const [like, setLike] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const userData = useStore((state) => state.user);
  const experiments = useExperiments(userData.userId);
  const [isOwner, setIsOwner] = useState(false);
  const ref = useRef(null);

  useAnimate([ref.current], 200, styles.visible);

  const shareData = {
    title: experimentData?.title ? experimentData.title : 'Unknown Title',
    text: experimentData?.description
      ? experimentData.description
      : 'Unknown Description',
    url: `/spaces/${experimentData?._id}`,
  };

  const handleLike = async () => {
    const response = await experiments.like(experimentData._id);

    if (response?.likes?.includes(experimentData._id)) {
      setLike(true);
    } else {
      setLike(false);
    }
  };

  const share = async () => {
    try {
      await navigator.share(shareData);
    } catch (e) {
      if (e.toString().includes('AbortError')) {
        console.log('Sharing aborted.');
      }
    }
  };

  const handleFavorite = async () => {
    const response = await experiments.favorite(experimentData._id);

    if (response?.favorites?.includes(experimentData._id)) {
      setFavorite(true);
    } else {
      setFavorite(false);
    }
  };

  // On load, check if user liked this project
  useEffect(() => {
    experimentData?.likedBy?.includes(userData.userId)
      ? setLike(true)
      : setLike(false);
    experimentData?.favoritedBy?.includes(userData.userId)
      ? setFavorite(true)
      : setFavorite(false);
    experimentData?.creator._id === userData.userId
      ? setIsOwner(true)
      : setIsOwner(false);
  }, [experimentData]);

  return (
    <div className={styles.container}>
      {!isOwner && (
        <>
          {experimentId && (
            <div
              className={styles.action}
              onClick={() => navigate(`/spaces/${experimentId}`)}
              ref={ref}
            >
              <CircleIcon iconWidth={'w-8'}>
                <ArrowRightAngle />
              </CircleIcon>
            </div>
          )}
          <div onClick={handleLike}>
            <CircleIcon
              variant={`${like ? 'filled' : variant}`}
              iconWidth="w-16"
            >
              <span className={styles.heart}>
                <Heart />
              </span>
            </CircleIcon>
          </div>
          <div onClick={handleFavorite}>
            <CircleIcon
              variant={`${favorite ? 'filled' : variant}`}
              iconWidth="w-12"
            >
              <Bookmark />
            </CircleIcon>
          </div>
        </>
      )}
      <div onClick={share}>
        <CircleIcon variant={variant} iconWidth="w-12">
          <ExternalLink />
        </CircleIcon>
      </div>
    </div>
  );
};

export default ProjectItemActions;
