import styles from "assets/styles/modules/InAppGuidance.module.scss";
import { motion } from "framer-motion";

const Container = ({ children }) => {
  const anim = {
    hidden: {
      opacity: 0,
      y: "40px",
      transition: {
        duration: 0.5,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
    visible: {
      opacity: 1,
      y: "0",
      transition: {
        duration: 0.5,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };

  return (
    <motion.div
      className={`${styles.container}`}
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={anim}
    >
      {children}
    </motion.div>
  );
};

export default Container;
