import styles from 'assets/styles/modules/ShareModal.module.scss';
import {modalAnimation} from 'utilities/animations';
import {motion} from 'framer-motion';
import Edit from 'assets/icons/Edit';
import QRCode from 'react-qr-code';
import {useRef} from 'react';
import Download from 'assets/icons/Download';
import cn from 'classnames';
import QrCode from 'assets/icons/QrCode';
import CopyLink from 'assets/icons/CopyLink';
import Close from 'assets/icons/Close';

import { print, } from 'react-html2pdf';

const ShareModal = ({experiment, height, setActive}) => {
  const qrCode = useRef(null);

  const downloadQR = () => {
    const svg = qrCode.current;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');

      const downloadLink = document.createElement('a');
      downloadLink.download = 'qrcode';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };

    img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
  };

  const shareData = {
    title: experiment?.title ? experiment.title : 'Off Space',
    text: experiment?.description ? experiment.description : '',
    url: `/spaces/${experiment?._id}`,
  };

  const handleShareClick = async () => {
    try {
      await navigator.share(shareData);
    } catch (e) {
      if (e.toString().includes('AbortError')) {
        console.log('Sharing aborted.');
      }
    }
  };

  const handleDownload = () => {
    // page needs to be loaded before it can be downloaded as PDF
    print(shareData.title, `dashboard-card-${experiment._id}`);
  };

  const handleQrCodeClick = () => {
    downloadQR();
  };

  return (
    <motion.div animate="visible" className={`${styles.wrapper}`} exit="hidden" initial="hidden" variants={modalAnimation}>
      <div className={`${styles.overlay}`} onClick={() => setActive(false)}>
      </div>

      <div className={`${styles.container} shadow-md`} style={{height: height + 'px'}}>
        <button className={styles.close} onClick={() => setActive(false)}>
          <span className="flex w-9">
            <Close color="#2f2f2f" />
          </span>
        </button>

        <ul className={styles.list}>
          <li>
            <button className={cn('font-semibold', styles.cta)} onClick={handleShareClick}>
              Share
              <span className={cn('w-14', styles.icon)}>
                <CopyLink />
              </span>
            </button>
          </li>

          <li>
            <button className={cn('font-semibold', styles.cta)} onClick={handleQrCodeClick}>
              QR Code
              <span className={cn('w-14', styles.icon)}>
                <QrCode />
              </span>

              <span style={{visibility: 'hidden', display: 'none'}}>
                <QRCode value={shareData.url} ref={qrCode} />
              </span>
            </button>
          </li>

          <li>
            <button className={cn('font-semibold', styles.cta)} onClick={handleDownload}>
              Download
              <span className={cn('w-11', styles.icon)}>
                <Download />
              </span>
            </button>
          </li>
        </ul>
      </div>
    </motion.div>
  );
};

export default ShareModal;
