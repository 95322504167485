import React from 'react';
import styles from 'assets/styles/modules/MainNavigation.module.scss';
import Chevron from 'assets/icons/Chevron';

const MainNavigation = ({setSelectedSubMenu}) => {
  return (
    <nav className={styles.container}>
      <ul>
        <li>
          <a href="#" onClick={() => setSelectedSubMenu('profile')}>
            Profile
            <span>
              <Chevron />
            </span>
          </a>
        </li>

        <li>
          <a href="#" onClick={() => setSelectedSubMenu('invite')}>
            Invite a Friend
            <span>
              <Chevron />
            </span>
          </a>
        </li>

        {/* <li>
          <a href="#" onClick={() => setSelectedSubMenu('plan')}>
            My Plan
            <span>
              <Chevron />
            </span>
          </a>
        </li> */}

        <li>
          <a href="#" onClick={() => setSelectedSubMenu('notifications')}>
            Notifications
            <span>
              <Chevron />
            </span>
          </a>
        </li>

        <li>
          <a href="#" onClick={() => setSelectedSubMenu('language')}>
            Language
            <span>
              <Chevron />
            </span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default MainNavigation;
