import React, {useEffect, useState} from 'react';
import FormFieldInfo from '../Forms/FormFieldInfo';
import FormCtaInfo from '../Forms/FormCtaInfo';
import UtilityButton from '../Buttons/UtilityButton';
import SubmenuHeading from './SubmenuHeading';
import styles from 'assets/styles/modules/Submenu.module.scss';
import useCustomer from 'lib/hooks/useCustomer';
import {useStore} from 'state/store';
import FormTextareaInfo from '../Forms/FormTextareaInfo';

const ProfileSubmenu = ({
  selectedSubMenu,
  closeSubmenu,
  setSelectedSubMenu,
}) => {
  const [avatar, setAvatar] = useState(null);
  const user = useCustomer();
  const userData = useStore((state) => state.user);

  useEffect(() => {
    if (userData) {
      setAvatar(userData.avatar);
    }
  }, [userData]);

  return (
    <div
      className={`${styles.container} ${
        selectedSubMenu === 'profile' || selectedSubMenu === 'profileEdit'
          ? styles.visible
          : ''
      } custom-scrollbar`}
    >
      <SubmenuHeading
        title="Profile"
        leftCtaTitle="Back" 
        leftCtaAction={closeSubmenu}
        rightCtaTitle="Edit"
        rightCtaAction={() => setSelectedSubMenu('profileEdit')}
      />

      <div className={styles.avatarContainer}>
        <div className={styles.avatar}>
          {avatar ? <img src={avatar} alt="Paralllel Avatar" /> : <></>}
        </div>
      </div>

      <FormFieldInfo title="Username" text={userData.username || ''} />
      <FormTextareaInfo title="Profile Bio" text={userData.bio || ''} maxCount="100" />
      <FormCtaInfo title="Profile Button" text={userData.ctaTitle || ''} />
      <FormFieldInfo title="E-mail" text={userData.email || ''} />
      <FormFieldInfo title="Phone Number" text={userData.phoneNumber || ''} />

      <div className={styles.ctaGroup}>
        <UtilityButton action={() => user.signOut()} title="Sign Out" />
        {/* TODO: Action should be replaced with delete account action */}
        <UtilityButton
          action={() => user.signOut()}
          title="Delete Account"
          warning
        />
      </div>
    </div>
  );
};

export default ProfileSubmenu;
