import React from 'react';
import {Field} from 'formik';
import CheckCircle from 'assets/icons/CheckCircle';
import styles from 'assets/styles/modules/FormPromptTextarea.module.scss';
import cn from 'classnames';

const FormPromptTextarea = ({
  id,
  title,
  errors,
  touched,
  labeled,
  handleChange,
  customChangeCallback,
}) => {
  return (
    <div className={styles.container}>
      <label htmlFor={id} className={cn({'visually-hidden': labeled})}>
        {title}
      </label>
      
      <Field component="textarea" id={id} name={id} placeholder={title} rows="5"
        onChange={
          handleChange
            ? (e) => {
                handleChange(e);
                customChangeCallback !== undefined &&
                  typeof customChangeCallback === 'function' &&
                  customChangeCallback(e.target.value);
              }
            : null
        }
      />
      {/* {errors[id] && touched[id] ? (
        <div className={cn([styles.message, 'sp1'])}>{errors[id]}</div>
      ) : null} */}
      {/* <span className="icon w-14">
        <CheckCircle />
      </span> */}
    </div>
  );
};

export default FormPromptTextarea;
