import React from "react";
import { Field, getIn } from "formik";

const FormFieldRepeater = ({
  customChangeCallback,
  errors,
  handleChange,
  id,
  labeled,
  placeholder,
  title,
  touched,
  type = "text",
  fieldIndex,
  value
}) => {
  const onChangeHandler = (e) => {
    if (!handleChange) return;

    handleChange(e);
    customChangeCallback !== undefined &&
      typeof customChangeCallback === "function" &&
      customChangeCallback(e.target.value, fieldIndex);
  };

  const error = getIn(errors, id);

  return (
    <div className="formRow">
      <label className={`formRow_label ${labeled ? "" : "visually-hidden"}`} htmlFor={id}>
        {title}
      </label>

      <Field
        id={id}
        name={id}
        onChange={onChangeHandler}
        placeholder={placeholder ? placeholder : title}
        type={type}
        value={value}
      />

      {error && (
        <div className="sp1 formRow_message">{error}</div>
      )}
    </div>
  );
};

export default FormFieldRepeater;
