import { Form, Formik } from "formik";
import { AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useStore } from "state/store";

import { resizeFile } from "utilities/resizeFile";
import useExperimentSections from "lib/hooks/useExperimentSections";
import useScrollBlock from "lib/hooks/useScrollBlock";
import {
  SpaceGalleryTextSchema,
  SpaceHomeTitleSchema,
  SpaceImagesSchema,
} from "schemas";

import FormEditImageMultiple from "components/common/Forms/FormEditImageMultiple";
import FormField from "../../Forms/FormField";
import GenerateButton from "components/common/Buttons/GenerateButton";
import Modal from "../Modal";
import ModalBody from "../ModalBody";
import ModalHeading from "../ModalHeading";
import ModalLevel from "../ModalLevel";
import ModalOptions from "../ModalOptions";
import ModalOptionsLine from "../ModalOptionsLine";
import SaveButton from "../SaveButton";
import FormTextarea from "components/common/Forms/FormTextarea";
import GenerateTextModal from "../GenerateTextModal";

const GalleryModal = ({
  active,
  setActive,
  content,
  setTempImages,
  tempImages,
  setTempText,
  setTempBodyText,
  tempText,
  tempBodyText,
}) => {
  const refTitle = useRef(null);
  const refImage = useRef(null);
  const refText = useRef(null);
  const [headerTitle, setHeaderTitle] = useState(null);
  const [activeLevel, setActiveLevel] = useState("");
  const sections = useExperimentSections();
  const userData = useStore((state) => state.user);
  const [openGenerateTextModal, setOpenGenerateTextModal] = useState(false);

  const handleOpenGenerateTextModal = () => {
    setOpenGenerateTextModal(!openGenerateTextModal);
  };

  useScrollBlock(active);

  const activateSubLevel = (levelName) => {
    setActiveLevel(levelName);
  };

  const handleBack = () => {
    setActiveLevel("");
    setTempImages(["", "", "", "", ""]);
  };

  const handleHeadingClose = () => {
    // Just close the modal
    setActive(false);
    setTempText(null);
    setTempBodyText(null);
    setTempImages(["", "", "", "", ""]);
  };

  const handleHeadingSubmit = () => {
    if (activeLevel === "Gallery Title") {
      // User is on sublevel, therefore we must send the form data to update the header
      submitForm();
    } else if (activeLevel === "Gallery Images") {
      submitImageForm();
    } else if (activeLevel === "Gallery Text") {
      submitTextForm();
    }
    setTimeout(() => {
      setActiveLevel("");
      setActive(false);
    }, 1000);
  };

  const handleRegenerateImage = async (imageUrl) => {
    const urlSplitBySlash = imageUrl.split("/");
    const imageId = urlSplitBySlash[urlSplitBySlash.length - 1].split(".")[0];

    const response = await sections.regenerateImage(
      userData.userId,
      content.rowId,
      imageId
    );
    const newTempImageUrls = content.images.map((originalImageUrl) =>
      originalImageUrl === imageUrl ? response.outputUrl : ""
    );
    const finalizedTempImageUrls = newTempImageUrls.map(
      (ti, i) => ti || tempImages[i]
    );
    setTempImages(finalizedTempImageUrls);
  };

  const handleRegenerateTextBody = async (textContent) => {
    const response = await sections.regenerateText(
      userData.userId,
      content.rowId,
      textContent
    );
    console.log(response);
    setTempBodyText(response.result);
  };

  const submitImageForm = () => {
    if (refImage.current) refImage.current.click();
  };

  const submitForm = () => {
    if (refTitle.current) refTitle.current.click();
  };

  const submitTextForm = () => {
    if (refText.current) refText.current.click();
  };

  useEffect(() => {
    if (activeLevel === "") {
      setHeaderTitle("Gallery");
    } else {
      setHeaderTitle(activeLevel);
    }
  }, [activeLevel]);

  return (
    <AnimatePresence>
      {active && (
        <>
          <GenerateTextModal
            setTempText={setTempBodyText}
            active={openGenerateTextModal}
            handleClose={handleOpenGenerateTextModal}
          />

          <Modal handleClose={handleHeadingClose}>
            <ModalHeading
              title={headerTitle}
              handleHeadingClose={handleHeadingClose}
              handleHeadingSubmit={handleHeadingSubmit}
              handleBack={handleBack}
              levelIsActive={activeLevel !== ""}
            />
            <ModalBody activeLevel={activeLevel}>
              <ModalOptions active={activeLevel === ""}>
                <ModalOptionsLine
                  handleClick={() => activateSubLevel("Gallery Title")}
                  title={"Title"}
                />

                <ModalOptionsLine
                  handleClick={() => activateSubLevel("Gallery Images")}
                  title={"Images"}
                />

                <ModalOptionsLine
                  handleClick={() => activateSubLevel("Gallery Text")}
                  title={"Text"}
                />
              </ModalOptions>

              {/* Title Level */}
              <ModalLevel active={activeLevel === "Gallery Title"} columns={1}>
                <div>
                  <Formik
                    initialValues={{
                      title: tempText !== null ? tempText : content?.texts[0],
                    }}
                    validationSchema={SpaceHomeTitleSchema}
                    onSubmit={async (values) => {
                      const updatedTexts = content.texts;
                      updatedTexts[0] = values.title;
                      const response = await sections.updateTexts(
                        userData.userId,
                        content.rowId,
                        updatedTexts
                      );
                      if (response?.success) setActive(false);
                    }}
                  >
                    {({ errors, touched, handleChange, submitForm }) => (
                      <>
                        <Form>
                          <FormField
                            customChangeCallback={setTempText}
                            errors={errors}
                            handleChange={handleChange}
                            id="title"
                            labeled="false"
                            title=""
                            touched={touched}
                            type="text"
                          />
                          <button
                            ref={refTitle}
                            style={{ display: "none" }}
                            type="submit"
                          >
                            <span>Save</span>
                          </button>
                        </Form>
                        <SaveButton handleSubmit={() => { submitForm(); handleBack(); }} />
                      </>
                    )}
                  </Formik>
                </div>
              </ModalLevel>

              {/* Image Level */}
              <ModalLevel active={activeLevel === "Gallery Images"} columns={2}>
                <Formik
                  initialValues={{ files: [null, null, null, null, null] }}
                  validationSchema={SpaceImagesSchema}
                  onSubmit={async (values) => {
                    const items = [];
                    const types = [];

                    for (const item of values.files) {
                      if (item) {
                        const base64 = await resizeFile(item);
                        items.push(base64);
                        types.push(item.type);
                      } else {
                        items.push(null);
                        types.push(null);
                      }
                    }

                    if (items.length > 0 && types.length > 0) {
                      const response = await sections.updateImages(
                        userData.userId,
                        content.rowId, // sectionId
                        items, // image base64 format array
                        types // contentTypes
                      );

                      if (response?.success) setActive(false);
                    } else {
                      console.log("Images array is empty");
                    }
                  }}
                >
                  {({ errors, touched, setFieldValue }) => (
                    <Form>
                      {content?.images?.map((img, i) => (
                        <FormEditImageMultiple
                          errors={errors}
                          img={tempImages[i] || img}
                          generate={handleRegenerateImage}
                          key={`galleryModal__${i}`}
                          multiple={true}
                          rowId={content.rowId}
                          setFieldValue={setFieldValue}
                          setTempImages={setTempImages}
                          slot={i}
                          tempImages={tempImages}
                          touched={touched}
                        />
                      ))}
                      <button
                        ref={refImage}
                        style={{ display: "none" }}
                        type="submit"
                      >
                        <span>Save</span>
                      </button>
                    </Form>
                  )}
                </Formik>
              </ModalLevel>

              {/* Text Level */}
              <ModalLevel active={activeLevel === "Gallery Text"} columns={1}>
                <div>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      text:
                        tempBodyText !== null
                          ? tempBodyText
                          : content?.texts[1],
                    }}
                    validationSchema={SpaceGalleryTextSchema}
                    onSubmit={async (values) => {
                      const updatedTexts = content.texts;
                      updatedTexts[1] = values.text;
                      const response = await sections.updateTexts(
                        userData.userId,
                        content.rowId,
                        updatedTexts
                      );
                      if (response?.success) setActive(false);
                    }}
                  >
                    {({ errors, touched, handleChange, submitForm }) => (
                      <>
                        <Form>
                          <FormTextarea
                            customChangeCallback={setTempBodyText}
                            errors={errors}
                            handleChange={handleChange}
                            id="text"
                            labeled={false}
                            touched={touched}
                            value={tempBodyText || content.texts[1]}
                            openGenerateModal={setOpenGenerateTextModal}
                          />
                        </Form>
                        <SaveButton handleSubmit={() => { submitForm(); handleBack(); }} />
                      </>
                    )}
                  </Formik>
                </div>
              </ModalLevel>
            </ModalBody>
          </Modal>
        </>
      )}
    </AnimatePresence>
  );
};

export default GalleryModal;
