import { useState, } from 'react';
import useExperiments from './useExperiments';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'state/store';

const useExperimentEditData = () => {
  const navigate = useNavigate();
  const [experiment, setExperiment] = useState({});
  const userData = useStore((state) => state.user);
  const experiments = useExperiments(userData.userId);

  const fetchPageContents = async(experimentId) => {
    // validate that the experiment is owned by editor
    experiments.fetchExperiment(experimentId)
               .then((data) => {
                 if (data.creatorId !== userData.userId) {
                   navigate(`/spaces/${data._id}`);
                 } else {
                   setExperiment(data);
                 }
               })
               .catch(() => navigate('/'));

    let pcs = [];
    const ex = await experiments.fetchExperiment(experimentId);

    const selectedRows = ex.pageOptionRows.map((row) => {
      return row.filter((pageOptionRow) => pageOptionRow.selected === true)[0];
    }).filter((val) => !!val);

    await Promise.all
      (selectedRows.map(async (row) => {
        return await experiments.fetchPageContents(row._id)
                                .then((data) => pcs.push({ ...data.sections[0], sectionType: row.pageSectionTemplate.sectionType, rowId: row._id, templateVertical: experiment.templateVertical, }))
                                .catch(() => console.log('error fetching pageContents'));
      })
    );

    return { creator: ex.creator, pageContents: pcs.sort((a, b) => a.position > b.position ? 1 : b.position > a.position ? -1 : 0), };
  }

  return {
    fetchPageContents,
    templateVerticalId: experiment.templateVertical,
  };
};

export default useExperimentEditData;
