import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import utilities from "utilities";

import Dashboard from "components/dashboard/_Dashboard";
import Edit from "components/edit/_Edit";
import Feed from "components/dashboard/_Feed";
import Header from "components/common/Header/Header";
import OverviewPage from "components/overview/_OverviewPage";
import PrivacyPolicyPage from "components/privacyPolicy/_PrivacyPolicyPage";
import RegistrationPage from "components/registration/_RegistrationPage";
import ShowExperimentPage from "components/edit/_Show";
import SignInPage from "components/signIn/_SignInPage";
import TermsOfUsePage from "components/termsOfUse/_TermsOfUsePage";
import WelcomePage from "components/welcome/_WelcomePage";
import Create from "components/createExperiment/_Create";
import ShowUserPage from "components/showUser/_ShowUserPage";

function App() {
  const { routes } = utilities;

  useEffect(() => {
    // Viewport Mobile Fix
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  return (
    <div className="app">
      <BrowserRouter>
        <div className="wrapper">
          <Routes>
            <>
              <Route
                exact
                element={<WelcomePage />}
                name="home"
                path={routes.home.path}
              />

              <Route
                exact
                element={<OverviewPage />}
                name="overview"
                path={routes.overview.path}
              />

              <Route
                exact
                element={<Create />}
                name="create"
                path={routes.experiments_create.path}
              />

              <Route
                exact
                element={<ShowExperimentPage />}
                name="show"
                path={routes.experiments_show.path}
              />

              <Route
                exact
                element={<ShowUserPage />}
                name="user"
                path={routes.user_show.path}
              />

              <Route
                exact
                element={<Edit />}
                name="edit"
                path={routes.experiments_edit.path}
              />

              <Route
                exact
                element={<Feed />}
                name="feed"
                path={routes.feed.path}
              />

              <Route
                exact
                element={<Dashboard />}
                name="dashboard"
                path={routes.dashboard.path}
              />

              <Route
                exact
                element={<PrivacyPolicyPage />}
                name="privacy-policy"
                path={routes.legal_privacyPolicy.path}
              />

              <Route
                exact
                element={<TermsOfUsePage />}
                name="terms-of-use"
                path={routes.legal_termsOfUse.path}
              />

              <Route
                exact
                element={<SignInPage />}
                name="sign-in"
                path={routes.users_signIn.path}
              />

              <Route
                exact
                element={<RegistrationPage utilities={utilities} />}
                name="sign-up"
                path={routes.users_signUp.path}
              />
            </>
          </Routes>
          
          <Header utilities={utilities} />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
