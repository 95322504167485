const Check = ({color}) => {
  return (
    <svg viewBox="0 0 11.44 8.37">
      <path
        id="Union"
        d="m11.22.22c.29.29.29.77,0,1.06l-6.79,6.79c-.39.39-1.02.39-1.41,0L.22,5.28c-.29-.29-.29-.77,0-1.06.29-.29.77-.29,1.06,0l2.44,2.44L10.16.22c.29-.29.77-.29,1.06,0Z"
        fill={color ? color : '#2f2f2f'}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Check;
