const ArrowRight = ({color}) => {
  return (
    <svg viewBox="0 0 9.89 8.92">
      <path
        d="m7.43,5.21H.75c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h6.68l-2.43-2.43c-.29-.29-.29-.77,0-1.06.29-.29.77-.29,1.06,0l3.54,3.54c.39.39.39,1.02,0,1.41l-3.54,3.54c-.29.29-.77.29-1.06,0-.29-.29-.29-.77,0-1.06l2.43-2.43Z"
        fill={color ? color : '#2f2f2f'}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default ArrowRight;
