import { useState } from 'react';

export default (input) => {
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const minSwipeDistance = 50;

  const end = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      input.onSwipedLeft();
    }
    if (isRightSwipe) {
      input.onSwipedRight();
    }
  };

  // Mouse events
  const onMouseDown = (e) => {
    setTouchEnd(0);
    setTouchStart(e.clientX);
  };

  const onMouseMove = (e) => {
    setTouchEnd(e.clientX);
  };

  const onMouseUp = (e) => {
    end();
  };

  // Touch events
  const onTouchStart = (e) => {
    setTouchEnd(0); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX || e.screenX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    end();
  };

  return {
    onMouseUp,
    onMouseDown,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    onMouseMove,
  };
};
