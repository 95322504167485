import Close from "assets/icons/Close";
import styles from "assets/styles/modules/FullScreenModal.module.scss";
import cn from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import { modalAnimation, revealOpacity } from "utilities/animations";

const FullScreenModal = ({
  children,
  active,
  setActive,
  submit,
  ctaIsDisabled,
}) => {
  return (
    <AnimatePresence>
      {active && (
        <motion.div animate="visible" className={styles.wrapper} exit="hidden" initial="hidden" variants={revealOpacity}>
          <div className={styles.wrapper}>
            <motion.div animate="visible" className={styles.container} exit="hidden" initial="hidden" variants={modalAnimation}>
              <div className={styles.close} onClick={() => setActive(false)}>
                <Close color={"#1c1c1c"} />
              </div>

              <div className={styles.body}>{children}</div>
              
              <div
                className={cn([
                  "shadow-sm",
                  styles.footer,
                  ctaIsDisabled ? styles.disabled : "",
                ])}
              >
                <button className="btn btn-primary" onClick={submit}>
                  Done
                </button>
              </div>
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FullScreenModal;
