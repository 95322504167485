import {useEffect, useRef, useState} from 'react';
import styles from 'assets/styles/modules/InAppGuidance.module.scss';
import {motion, AnimatePresence} from 'framer-motion';
import cn from 'classnames';
import useSwipe from 'lib/hooks/useSwipe';
import Container from './Container';
import Close from 'assets/icons/Close';
import ArrowRight from 'assets/icons/ArrowRight';

const InAppGuidanceSlider = ({slides, heading, active, setActive}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const container = useRef(null);

  const handleNextSlide = () => {
    if (currentSlide === slides.length - 1) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide((prevSlide) => prevSlide + 1);
    }
  };

  const handlePrevSlide = () => {
    if (currentSlide === 0) {
      setCurrentSlide(slides.length - 1);
    } else {
      setCurrentSlide((prevSlide) => prevSlide - 1);
    }
  };

  const handleClose = () => {
    setActive(false);
  };

  const swipeHandlers = useSwipe({
    onSwipedLeft: handleNextSlide,
    onSwipedRight: handlePrevSlide,
  });

  // Animate the slide change and play the slide video if it exists
  useEffect(() => {
    if(active) {
      const videos = container.current.querySelectorAll('video');
      const activeVideo = container.current.querySelector('.slide.active video');
  
      container.current.scrollTo({
        left: (container.current.offsetWidth - 36) * currentSlide,
        behavior: 'smooth',
      });
  
      if (activeVideo) {
        activeVideo.play();
      } else {
        videos?.forEach((video) => {
          video.pause();
          video.currentTime = 0;
        });
      }
    }
  }, [currentSlide, active]);

  return (
    <AnimatePresence>
      {active && (
        <>
          <div className={styles.overlay}></div>
          <Container>
            <header className={cn('bg-gradient shadow-md', styles.header)}>
              <h2 className="font-semibold">{heading}</h2>
              <button className="close-button" onClick={handleClose}>
                <div className="w-12">
                  <Close />
                </div>
              </button>
            </header>

            <div className={styles.slider}>
              <div className={styles.inner} {...swipeHandlers}>
                <motion.div className={styles.slides} ref={container}>
                  {slides?.map((slide, index) => (
                    <div key={index} className={styles.slide}>
                      <motion.div
                        key={slide.heading}
                        className={cn(styles.media, {
                          active: index === currentSlide,
                        })}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: 0.5}}
                      >
                        {slide.video ? (
                          <video loop muted>
                            <source src={slide.video} type="video/mp4" />
                          </video>
                        ) : (
                          <img
                            src={`/imgs/in-app-guidance/${slide.image}`}
                            alt={slide.title}
                          />
                        )}
                      </motion.div>
                    </div>
                  ))}
                </motion.div>

                <div className={styles.body}>
                  <div className={styles.dots}>
                    {slides.map((_, index) => (
                      <span
                        key={index}
                        className={cn(
                          'transition-all',
                          styles.dot,
                          index === currentSlide ? styles.active : '',
                        )}
                      />
                    ))}
                  </div>
                  <AnimatePresence>
                    <motion.div
                      key={`text__${currentSlide}`}
                      initial={{opacity: 0}}
                      animate={{opacity: 1}}
                      exit={{opacity: 0}}
                      transition={{duration: 0.5}}
                      className={cn('sp1', styles.text)}
                    >
                      <h3 className="sp1 font-semibold mb-10">
                        {slides[currentSlide].title}
                      </h3>
                      <p>{slides[currentSlide].description}</p>
                    </motion.div>
                  </AnimatePresence>
                </div>
              </div>
            </div>

            <div className={styles.nav}>
              <div className={styles.buttons}>
                <button
                  className={cn('transition-all', styles.button)}
                  onClick={handlePrevSlide}
                  disabled={currentSlide === 0}
                >
                  <span className="inline-block w-11 rotate-180">
                    <ArrowRight />
                  </span>
                </button>

                <button className={cn('transition-all', styles.button)} onClick={handleNextSlide} disabled={currentSlide === slides.length - 1}>
                  <span className="inline-block w-11">
                    <ArrowRight />
                  </span>
                </button>
              </div>
            </div>
          </Container>
        </>
      )}
    </AnimatePresence>
  );
};

export default InAppGuidanceSlider;
