import ModalHeading from "../ModalHeading";
import ModalOptions from "../ModalOptions";
import ModalOptionsLine from "../ModalOptionsLine";
import FormField from "../../Forms/FormField";
import { Form, Formik } from "formik";
import { SpaceHeaderSchema } from "schemas";
import useExperimentSections from "lib/hooks/useExperimentSections";
import Modal from "../Modal";
import ModalLevel from "../ModalLevel";
import { AnimatePresence } from "framer-motion";
import { useStore } from "state/store";
import useScrollBlock from "lib/hooks/useScrollBlock";
import { useParams } from "react-router-dom";
import useExperiments from "lib/hooks/useExperiments";
import ModalBody from "../ModalBody";
import useSectionModal from "lib/hooks/useSectionModal";
import SaveButton from "../SaveButton";

const HeaderModal = ({
  active,
  setActive,
  title,
  columns,
  content,
  tempTitle,
  setTempTitle,
}) => {
  const { experimentId } = useParams();
  const sections = useExperimentSections();
  const userData = useStore((state) => state.user);
  const experiment = useExperiments(userData.userId);

  useScrollBlock(active);

  const sectionModal = useSectionModal(
    content,
    setActive,
    null,
    null,
    null,
    tempTitle,
    "Header"
  );

  return (
    <AnimatePresence>
      {active && (
        <Modal handleClose={sectionModal.handleHeadingClose}>
          <ModalHeading
            title={sectionModal.headerTitle}
            handleBack={sectionModal.handleBack}
            handleHeadingClose={sectionModal.handleHeadingClose}
            handleHeadingSubmit={sectionModal.handleHeadingSubmit}
            levelIsActive={sectionModal.activeLevel === "Name"}
          />

          <ModalBody activeLevel={sectionModal.activeLevel === "Name"}>
            <ModalOptions active={sectionModal.activeLevel !== "Name"}>
              <ModalOptionsLine handleClick={() =>
                    sectionModal.activateSubLevel("Name")
                  } title={"Name"} />
            </ModalOptions>

            <ModalLevel active={sectionModal.activeLevel === "Name"} columns={columns}>
              <Formik
                initialValues={{
                  name: content?.texts[0] || "",
                }}
                validationSchema={SpaceHeaderSchema}
                onSubmit={async (values) => {
                  const updatedTexts = content.texts;
                  updatedTexts[0] = values.name;
                  const response = await sections.updateTexts(
                    userData.userId,
                    content.rowId,
                    updatedTexts
                  );
                  const responseExperiment = await experiment.updateExperiment({
                    _id: experimentId,
                    title: values.name,
                  });
                  if (response?.success && responseExperiment?.success) {
                    setActive(false);
                  }
                }}
              >
                {({ errors, touched, handleChange, submitForm }) => (
                  <>
                    <Form>
                      <FormField
                        customChangeCallback={setTempTitle}
                        errors={errors}
                        handleChange={handleChange}
                        id="name"
                        labeled="true"
                        touched={touched}
                        type="text"
                      />
                    </Form>
                    <SaveButton handleSubmit={() => { submitForm(); sectionModal.handleBack(); }} />
                  </>
                )}
              </Formik>
            </ModalLevel>
          </ModalBody>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default HeaderModal;
