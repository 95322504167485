import React from 'react';
import MainNavigation from './MainNavigation';
import ProfileSubmenu from './ProfileSubmenu';
import NotificationsSubmenu from './NotificationsSubmenu';
import LanguageSubmenu from './LanguageSubmenu';
import MainNavigationFooter from './MainNavigationFooter';
import styles from 'assets/styles/modules/Menu.module.scss';
import ProfileEditSubmenu from './ProfileEditSubmenu';
import PlanSubmenu from './PlanSubmenu';
import CreditsInfoSubmenu from './CreditsInfoSubmenu';
import InviteSubmenu from './InviteSubmenu';

const Menu = ({menuIsOpen, selectedSubMenu, setSelectedSubMenu}) => {
  const closeSubmenu = () => {
    setSelectedSubMenu('');
  };

  return (
    <div className={`${styles.container} ${menuIsOpen ? styles.visible : ''}`}>
      <div className={styles.navigation}>
        <MainNavigation setSelectedSubMenu={setSelectedSubMenu} />
        <MainNavigationFooter />
      </div>

      <ProfileSubmenu
        selectedSubMenu={selectedSubMenu}
        closeSubmenu={closeSubmenu}
        setSelectedSubMenu={setSelectedSubMenu}
      />

      <ProfileEditSubmenu
        selectedSubMenu={selectedSubMenu}
        setSelectedSubMenu={setSelectedSubMenu}
      />

      <InviteSubmenu
        selectedSubMenu={selectedSubMenu}
        closeSubmenu={closeSubmenu}
      />

      <PlanSubmenu
        selectedSubMenu={selectedSubMenu}
        closeSubmenu={closeSubmenu}
        setSelectedSubMenu={setSelectedSubMenu}
      />

      <CreditsInfoSubmenu
        selectedSubMenu={selectedSubMenu}
        setSelectedSubMenu={setSelectedSubMenu}
      />

      <NotificationsSubmenu
        selectedSubMenu={selectedSubMenu}
        closeSubmenu={closeSubmenu}
      />

      <LanguageSubmenu
        selectedSubMenu={selectedSubMenu}
        closeSubmenu={closeSubmenu}
      />
    </div>
  );
};

export default Menu;
