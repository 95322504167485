import styles from 'assets/styles/modules/Modal.module.scss';
import { motion } from 'framer-motion';
import { modalAnimation } from 'utilities/animations';

const Modal = ({ handleClose, children, fullsize, }) => {
  return (
    <motion.div
      className={`${styles.wrapper}`}
      initial='hidden'
      animate='visible'
      exit='hidden'
      variants={modalAnimation}
    >
      <div className={styles.overlay} onClick={handleClose}></div>

      <div className={`${styles.container} ${fullsize ? styles.fullsize : ''} shadow-md custom-scrollbar`}>
        {children}
      </div>
    </motion.div>
  );
};

export default Modal;
