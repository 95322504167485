import {useNavigate} from 'react-router-dom';
import styles from 'assets/styles/modules/DashboardActions.module.scss';
import Plus from 'assets/icons/Plus';
import useAnimate from 'lib/hooks/useAnimate';
import {useRef} from 'react';

const DashboardActions = () => {
  const navigate = useNavigate();
  const ref = useRef(null);

  useAnimate([ref.current], 500, styles.visible);

  return (
    <div className={`${styles.container} sp1 font-semibold`} ref={ref}>
      {/* <div className={styles.item}>
        <a className={ window.location.href.includes('dashboard') ? styles.selected : '' } href={'/dashboard?section=spaces'}>
          <button className={ window.location.href.includes('dashboard') ? `${styles.button} ${styles.selected}` : styles.button }>
          </button>
        
          Home
        </a>
      </div> */}

      <div className={styles.item}>
        <button className={`${styles.icon} ${styles.filled}`} onClick={() => navigate('/spaces/create', {state: {activeStep: 1}})}>
          <span className={styles.plus}>
            <Plus color="#FFFFFF" />
          </span>
        </button>
      </div>

      {/* <div className={styles.item}>
        <a className={ window.location.href.includes('feed') ? styles.selected : '' } href={'/feed'}>
          <button className={ window.location.href.includes('feed') ? `${styles.button} ${styles.selected}` : styles.button }>
          </button>

          Discover
        </a>
      </div> */}
    </div>
  );
};

export default DashboardActions;
