import React from 'react';

import styles from 'assets/styles/modules/OverviewPage.module.scss';

function OverviewPage() {
  return (
    <iframe className={styles.container} src="https://off-space-webflow-a436390f4823.herokuapp.com" />
  )
}

export default OverviewPage;
