import CopyLink from "assets/icons/CopyLink";
import SubmenuHeading from "./SubmenuHeading";
import styles from "assets/styles/modules/Submenu.module.scss";
import cn from "classnames";
import useCustomer from "lib/hooks/useCustomer";
import { useEffect, useState } from "react";
import FullScreenMessage from "../FullScreenMessage";
import { siteUrl } from "constants";

const InviteCta = ({ children, disabled, code, setShowSuccess }) => {
  const handleClick = () => {
    if (disabled) return;
    navigator.clipboard.writeText(`${siteUrl}/signup?code=${code}`);
    setShowSuccess(true);
  };

  return (
    <button
      className={cn(["st2", styles.inviteCta, disabled ? "" : "bg-gradient"])}
      disabled={disabled}
      onClick={handleClick}
    >
      {children}
      <span>
        <CopyLink color={disabled ? "#9A9899" : "white"} />
      </span>
    </button>
  );
};

const InviteSubmenu = ({ selectedSubMenu, closeSubmenu }) => {
  const [codes, setCodes] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const user = useCustomer();

  useEffect(() => {
    user.getInviteCodes()
        .then((response) => {
          if (response.length < 2) {
            user.createInviteCode().then((response) => {
              user.getInviteCodes().then((response) => setCodes(response))
          });
          } else {
            setCodes(response);
          }
        });
  }, [user, ])

  return (
    <>
      <div
        className={`${styles.container} ${
          selectedSubMenu === "invite" ? styles.visible : ""
        } custom-scrollbar`}
      >
        <div className={styles.main}>
          <SubmenuHeading leftCtaAction={closeSubmenu} leftCtaTitle="Back" title="Invite a Friend" />

          <div className="font-semibold mb-32">
            Congratulations, you are one of the first members of OffSpace. For
            the moment, we are an invite-only platform. You can invite up to two
            friends, so choose them carefully.
          </div>

          <div className={cn(["mb-32", styles.box])}>
            <h3 className="font-semibold mb-10">How it works?</h3>

            <ol className="sp1 circled-ol">
              <li>Click on the Invite you want to share.</li>
              <li>Share the copied link to a friend via text message.</li>
            </ol>
          </div>

          <div className="flex flex-col gap-12">
            {codes?.map((code, i) => (
              <InviteCta
                disabled={code.user ? true : false}
                code={code.code}
                key={code.code}
                setShowSuccess={setShowSuccess}
              >
                Invitation {`0${i + 1}`}
              </InviteCta>
            ))}
          </div>
        </div>
      </div>

        <FullScreenMessage active={showSuccess} setActive={setShowSuccess}>
          <h3>Link Copied!</h3>
          <p>You can now past it and send it through your favourite messaging service.</p>
          <p>Your invitation can only be used once.</p>
        </FullScreenMessage>

    </>
  );
};

export default InviteSubmenu;
