import { useEffect, useState } from "react";
import ModalHeading from "../ModalHeading";
import ModalOptions from "../ModalOptions";
import ModalOptionsLine from "../ModalOptionsLine";
import { Form, Formik } from "formik";
import { SpaceImagesSchema } from "schemas";
import useExperimentSections from "lib/hooks/useExperimentSections";
import Modal from "../Modal";
import ModalLevel from "../ModalLevel";
import { AnimatePresence, motion } from "framer-motion";
import { useStore } from "state/store";
import useScrollBlock from "lib/hooks/useScrollBlock";
import ModalBody from "../ModalBody";
import SaveButton from "../SaveButton";
import FormEditImageRepeater from "components/common/Forms/FormEditImageRepeater";
import ImageEditModal from "../ImageEditModal";
import GenerateImageModal from "../GenerateImageModal";
import { revealOpacity } from "utilities/animations";

const ImagesModal = ({
  active,
  setActive,
  content,
  setTempImages,
  tempImages,
}) => {
  const [headerTitle, setHeaderTitle] = useState(null);
  const [activeLevel, setActiveLevel] = useState("");
  const sections = useExperimentSections();
  const userData = useStore((state) => state.user);
  const [imageEditModalIsActive, setImageEditModalIsActive] = useState(false);
  const [generateImageModalIsActive, setGenerateImageModalIsActive] =
    useState(false);
  const [activeSlot, setActiveSlot] = useState(0);
  const [linkCount, setLinkCount] = useState(parseInt(tempImages.length));

  useScrollBlock(active);

  const activateSubLevel = (levelName) => {
    setActiveLevel(levelName);
  };

  const handleBack = () => {
    setActiveLevel("");
    setTempImages(content?.images || []);
  };

  const handleHeadingClose = () => {
    // Just close the modal
    setActive(false);
    setTempImages(content?.images || []);
  };

  useEffect(() => {
    if (activeLevel === "") {
      setHeaderTitle("Images");
    } else {
      setHeaderTitle(activeLevel);
    }
  }, [activeLevel]);

  const handleSetTempImage = (base64, slot) => {
    const newTempImages = [...tempImages];
    newTempImages[slot] = base64;
    setTempImages(newTempImages);
  };

  const handleModalSetTempImage = (base64) => {
    const newTempImages = [...tempImages];
    newTempImages[activeSlot] = base64;
    setTempImages(newTempImages);
  };

  const handleOpenImageEditModal = (slot) => {
    setActiveSlot(slot);
    setImageEditModalIsActive(true);
  };

  const handleOpenGenerateImageModal = (slot) => {
    setActiveSlot(slot);
    setGenerateImageModalIsActive(true);
  };

  const closeImageEditModal = () => {
    setImageEditModalIsActive(false);
  };

  const closeGenerateImageModal = () => {
    setGenerateImageModalIsActive(false);
  };

  const handleLinkCount = (action) => {
    if (action === "inc" && linkCount < 4) {
      setLinkCount(linkCount + 1);
    } else if (action === "dec") {
      if (linkCount > 1) setLinkCount(linkCount - 1);
    }
  };

  return (
    <AnimatePresence>
      {active && (
        <>
          <ImageEditModal active={imageEditModalIsActive} handleClose={closeImageEditModal} img={tempImages[activeSlot] || content.images[activeSlot]} rowId={content.rowId} setTempImage={handleModalSetTempImage} />

          <GenerateImageModal active={generateImageModalIsActive} handleClose={closeGenerateImageModal} setTempImage={handleSetTempImage} slot={activeSlot} />

          <Modal handleClose={handleHeadingClose}>
            <ModalHeading
              handleBack={handleBack}
              handleHeadingClose={handleHeadingClose}
              levelIsActive={activeLevel !== ""}
              title={headerTitle}
            />

            <ModalBody activeLevel={activeLevel}>
              <ModalOptions active={activeLevel === ""}>
                <ModalOptionsLine handleClick={() => activateSubLevel("Images")} title={"Images"} />
              </ModalOptions>

              {/* Image Level */}
              <ModalLevel active={activeLevel === "Images"} columns={2}>
                <Formik
                  initialValues={{ files: [null, null, null, null] }}
                  validationSchema={SpaceImagesSchema}
                  onSubmit={async (values) => {
                    if (tempImages.length === 0) return;

                    /* 
                    tempImages will store the array of images. 
                    These images can be a base64 or an existing image URL. 
                    The API endpoint associated with updateImages will check the type of the image 
                    and handle it accordingly.

                    Once images are uploaded, the API will return the updated content object.
                    The ImagesSection will then update its grid type depending on the amount of images.
                    */

                    const response = await sections.updateImages(userData.userId, content.rowId, tempImages, "image/png");
                    
                    if (response) setActive(false);
                  }}
                >
                  {({ errors, touched, setFieldValue, submitForm }) => (
                    <>
                      <Form>
                        <AnimatePresence>
                          {Array(linkCount)
                            .fill()
                            .map((image, i) => (
                              <motion.div
                                animate="visible"
                                className="mb-32"
                                exit="hidden"
                                initial="hidden"
                                key={`imagesModal__${i}`}
                                variants={revealOpacity}
                              >
                                <FormEditImageRepeater
                                  errors={errors}
                                  id={`files[${i}]`}
                                  img={tempImages[i]}
                                  openGenerateImageModal={() => handleOpenGenerateImageModal(i)}
                                  openImageEditModal={() => handleOpenImageEditModal(i)}
                                  rowId={content.rowId}
                                  setFieldValue={setFieldValue}
                                  setTempImage={handleSetTempImage}
                                  slot={i}
                                  touched={touched}
                                />
                              </motion.div>
                            ))}
                        </AnimatePresence>
                      </Form>

                      <div className="btn-container__paired">
                        <button className="btn btn-secondary " onClick={() => handleLinkCount("dec")}>
                          Remove Image
                        </button>

                        <button className="btn btn-secondary " onClick={() => handleLinkCount("inc")}>
                          Add Image
                        </button>
                      </div>

                      <SaveButton handleSubmit={submitForm} />
                    </>
                  )}
                </Formik>
              </ModalLevel>
            </ModalBody>
          </Modal>
        </>
      )}
    </AnimatePresence>
  );
};

export default ImagesModal;
