import { motion, } from 'framer-motion';
import { useState, } from 'react';

import useSelectTemplate from 'lib/hooks/useSelectTemplate';
import useSwipeHandlers from 'lib/hooks/useSwipeHandlers';
import { spring, } from 'utilities/animations';

import Bin from 'assets/icons/Bin';
import CircleIcon from 'assets/icons/CircleIcon';
import EditButton from './ui/EditButton';
import globalSectionStyles from 'assets/styles/modules/Section.module.scss';
import Plus from 'assets/icons/Plus';
import styles from 'assets/styles/modules/QuoteSection.module.scss';

import { evaluateWithNewlines, } from 'utilities/text';

import QuoteModal from 'components/common/Modal/Quote/QuoteModal';

const QuoteSection = ({ content, isShowPage, openEdit, removeSection }) => {
  const [active, setActive] = useState(false);
  const { swipeHandlers, layout } = useSwipeHandlers(content.variationNumber);
  const [tempText, setTempText] = useState(null);
  useSelectTemplate(content, layout === 0 ? 1 : layout);

  return (
    <>
      <section { ...(isShowPage ? [] : swipeHandlers) } className={globalSectionStyles.container} draggable='false' style={{ userSelect: 'none' }}>
        <QuoteModal
          active={active}
          setActive={setActive}
          title='Quote'
          content={content}
          setTempText={setTempText}
          tempText={tempText}
        />

        { openEdit &&
          <EditButton openModal={setActive} position='bottomRightNoImage' />
        }

        <div className={`${isShowPage ? globalSectionStyles.contentFullPageView : globalSectionStyles.contentEditView} ${styles.content}`} data-layout={layout === 0 ? 1 : layout}>
          <motion.div className={styles.text} layout transition={spring}>
            {evaluateWithNewlines(tempText ? tempText : content?.texts[0] || '')}
          </motion.div>
        </div>

        { removeSection &&
          <div className={globalSectionStyles.delete} onClick={() => removeSection(content.rowId)}>
            <CircleIcon variant={'filledInverted'} iconWidth={'w-14'}>
              <Bin />
            </CircleIcon>
          </div>
        }

        { openEdit &&
          <div className={globalSectionStyles.addSection} onClick={ () => openEdit({ status: true, afterPosition: content.position }) }>
            <CircleIcon iconWidth={'w-12'}>
              <Plus />
            </CircleIcon>
          </div>
        }
      </section>

      { openEdit &&
        <div className={globalSectionStyles.divider}></div>
      }
    </>
  );
};

export default QuoteSection;
